import { IRoseAGGridColumn } from '../types/agGrid';
import { sexFormatter, arrayFormatter } from '../gridUtils';

export const patientExportColumns: IRoseAGGridColumn<any>[] = [
  {
    headerName: 'Anrede',
    field: 'geschlecht',
    width: 40,
    excelValueFormatter: sexFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Titel',
    field: 'titel',
    width: 60,
  },
  {
    headerName: 'Name',
    field: 'name',
    width: 120,
  },
  {
    headerName: 'Vorname',
    field: 'vorname',
    width: 120,
  },
  {
    headerName: 'Telefon',
    field: 'fon',
    width: 160,
    excelValueFormatter: arrayFormatter,
  },
  {
    headerName: 'E-Mail',
    field: 'email',
    width: 160,
  },
  {
    headerName: 'Pat.-ID',
    field: 'patid',
    width: 100,
  },
];
