import { NameResolverService } from '@/services/nameResolverService';
import { FolgeTermineTerminInfoResolverService } from '@/services/terminInfoFolgetermineResolverService';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { authStore } from './authStore';
import { behandlerStore } from './behandlerStore';
import { teamsStore } from './teamsStore';
import { settingsStore } from '@/state/settingsStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { UserPrivacyLevel } from '@/../../types';
import { TerminInfoResolverService } from '@/services/terminInfoResolverService';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { store, rootActionContext } = createDirectStore({
  state: () => ({
    clientDataInitialized: false,
    lastClientId: '',
    privacyLevel: UserPrivacyLevel.NONE,
    roseHelperActive: false,
    nameResolver: new NameResolverService(),
    folgeterminInfoResolver: new FolgeTermineTerminInfoResolverService(),
    terminInfoResolver: new TerminInfoResolverService(),
    uiHost: '',
  }),
  getters: {
    isFakeData(state) {
      return state.privacyLevel !== UserPrivacyLevel.NONE;
    },
    clientDataInitialized(state) {
      return state.clientDataInitialized;
    },
    names(state) {
      return state.nameResolver;
    },
    folgeterminInfo(state) {
      return state.folgeterminInfoResolver;
    },
    terminInfo(state) {
      return state.terminInfoResolver;
    },
    settings() {
      return settingsStore.state.settings;
    },
  },
  mutations: {
    setUiHost(state, uiHost: string) {
      state.uiHost = uiHost;
    },
    setInitialized(state, status: boolean) {
      state.clientDataInitialized = status;
    },
    setLastClientId(state, cid: string) {
      state.lastClientId = cid;
    },
    setRoseHelperActive(state, active: boolean) {
      state.roseHelperActive = active;
    },
    setPrivacyLevel(state, privacyLevel: UserPrivacyLevel) {
      state.privacyLevel = privacyLevel;
      state.nameResolver.setFakeData(privacyLevel);
    },
  },
  actions: {
    async setClientId(context, cid: string) {
      const { state, dispatch, commit } = rootActionContext(context);

      if (state.lastClientId === cid) {
        return;
      }
      commit.setLastClientId(cid);

      await dispatch.clearClientCaches();
      await dispatch.loadInitStaticData();
    },
    async loadInitStaticData(context) {
      const { commit } = rootActionContext(context);

      await authStore.dispatch.getProfile();
      if (authStore.getters.hasMetrics) {
        await Promise.all([
          behandlerStore.dispatch.load(),
          teamsStore.dispatch.getData(),
          staticDataMetricsStore.dispatch.init(),
          settingsStore.dispatch.init(),
        ]);
      }

      commit.setInitialized(true);
    },
    clearClientCaches() {
      behandlerStore.commit.clear();
      teamsStore.commit.clearCache();
    },
    async setUseFakeDataForSensitiveData(context, newValue: UserPrivacyLevel) {
      console.log('setUseFakeDataForSensitiveData', newValue);
      const { state, commit } = rootActionContext(context);
      if (state.privacyLevel !== newValue) {
        commit.setPrivacyLevel(newValue);
        await clientStateStore.dispatch.clearClientCaches();
        await behandlerStore.dispatch.load();
        await teamsStore.dispatch.getData();
      }
    },
  },
});

export const clientStateStore = store;
