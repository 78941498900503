import { roseDayjs } from '../../../base';

export const humanizeDiffFilter = {
  filters: {
    humanizeDiff(value: string | Date, prefix = false): string {
      let m = roseDayjs(value);
      if (value && m.isValid()) {
        let diff = m.diff(roseDayjs(), 'seconds');
        let dur = roseDayjs.duration(diff, 'seconds');
        return dur.humanize(prefix);
      }
      return '';
    },
    humanizeFromNow(value: string | Date, prefix = false): string {
      let m = roseDayjs(value);
      if (value && m.isValid()) {
        return m.fromNow(!prefix);
      }
      return '';
    },
  },
};
