import { defineComponent } from 'vue';
import { Doughnut } from 'vue-chartjs';
export default defineComponent({
  "extends": Doughnut,
  props: {
    data: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    labels: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    title: {
      type: String,
      "default": function _default() {
        return '-';
      }
    },
    colors: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    showLabels: {
      type: Boolean,
      "default": false
    },
    showLegend: {
      type: Boolean,
      "default": false
    }
  },
  watch: {
    data: function data() {
      this.updateChart();
    }
  },
  mounted: function mounted() {
    this.updateChart();
  },
  methods: {
    updateChart: function updateChart() {
      // @ts-ignore
      this.renderChart({
        labels: this.showLabels ? this.labels : [],
        datasets: [{
          label: this.title,
          backgroundColor: this.colors,
          data: this.data
        }]
      }, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.showLegend,
          position: 'bottom'
        }
      });
    }
  }
});