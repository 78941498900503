import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import OpenInCharly from '@/components/OpenInCharly.vue';
import PARCaseInfo from '@/components/PARCaseInfo.vue';
import { openLinkInUi } from '@/uiCommunication';
export default defineComponent({
  components: {
    OpenInCharly: OpenInCharly,
    PARCaseInfo: PARCaseInfo
  },
  props: ['entry'],
  data: function data() {
    return {
      dialog: false,
      params: null,
      nachname: null,
      vorname: null,
      par: null,
      patid: null,
      privat: null,
      veraltet: null,
      showVeraltet: true,
      cellValue: null
    };
  },
  computed: {
    useEntry: function useEntry() {
      return this.entry || this.params;
    }
  },
  watch: {
    useEntry: function useEntry() {
      this.setValues();
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    // this.cellValue = this.getValueToDisplay(this.params);
    this.showVeraltet = !!this.useEntry.showVeraltet;
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      if (this.useEntry && this.useEntry.data) {
        this.nachname = this.useEntry.data.name;
        this.vorname = this.useEntry.data.vorname;
        this.patid = this.useEntry.data.patid;
        this.privat = this.useEntry.data.privatpatient;
        this.veraltet = this.useEntry.data.veraltet;
        this.par = this.useEntry.data.par;
        this.dialog = this.useEntry.context && this.useEntry.context.dialog || '';
      }
    },
    onOpenPatient: function onOpenPatient() {
      console.log('Open patient charly');
    },
    showPatientDetails: function showPatientDetails() {
      openLinkInUi("patienten?pat=".concat(this.patid));
    },
    copySuccess: function copySuccess(value) {
      // TODO
      console.log('Copied', value);
    },
    copyError: function copyError(value) {// TODO
    }
  }
});