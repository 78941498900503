import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    options: function options() {
      return this.params.context.options;
    },
    selected: {
      get: function get() {
        return this.params.value;
      },
      set: function set(value) {
        this.params.context.itemChanged({
          id: this.params.data.id,
          value: value
        });
      }
    }
  }
});