import { defineComponent } from 'vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip
  },
  data: function data() {
    return {
      params: null,
      text: ''
    };
  },
  mounted: function mounted() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      // set value into cell again
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      this.text = this.params.valueFormatted;
    }
  }
});