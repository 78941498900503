export const uhrFilter = {
  filters: {
    uhr(value: string): string {
      if (value.indexOf(':') >= 0) {
        return `${value} Uhr`;
      }
      return `${value}:00 Uhr`;
    },
  },
};
