import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    BehandlerChip: BehandlerChip
  },
  data: function data() {
    return {
      params: {},
      event: '',
      terminToAdd: null
    };
  },
  computed: {
    terminBehandlerNotAssignedAktiv: function terminBehandlerNotAssignedAktiv() {
      return editSettingsStore.getters.terminBehandlerNichtZugeordnetAktiv;
    },
    terminBehandlerNotAssignedVeraltet: function terminBehandlerNotAssignedVeraltet() {
      return editSettingsStore.getters.terminBehandlerNichtZugeordnetVeraltet;
    },
    terminBehandlerAssigned: function terminBehandlerAssigned() {
      if (!this.params.data) {
        return [];
      }

      return editSettingsStore.getters.terminBehandlerMap[this.params.data];
    }
  },
  methods: {
    addTerminbehandlerZuordnung: function addTerminbehandlerZuordnung(terminBehandler) {
      if (this.params.data) {
        editSettingsStore.commit.assignTerminBehandlerToBehandler({
          behandlerId: this.params.data,
          terminBehandlerId: terminBehandler.extid
        });
      }
    },
    deleteTerminBehandlerZuordnung: function deleteTerminBehandlerZuordnung(terminBehandler) {
      if (this.params.data) {
        editSettingsStore.commit.removeTerminBehandlerAssignment({
          behandlerId: this.params.data,
          terminBehandlerId: terminBehandler.extid
        });
      }
    },
    getIsAutoConfigured: function getIsAutoConfigured(t) {
      var _a, _b, _c;

      var grund = (_c = (_b = (_a = editSettingsStore.getters.terminBehandlerZuordnungMeta) === null || _a === void 0 ? void 0 : _a.auto) === null || _b === void 0 ? void 0 : _b[t.extid]) === null || _c === void 0 ? void 0 : _c.grund;
      return !!grund;
    }
  }
});