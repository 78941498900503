import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
import { map, compact, chain, take } from 'lodash';
import { behandlerStore } from '@/state/behandlerStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
var maxMembersToShow = 20;
export default defineComponent({
  name: 'TeamMitgliederCell',
  components: {
    BehandlerChip: BehandlerChip
  },
  data: function data() {
    return {
      params: {},
      showAll: false
    };
  },
  computed: {
    mitglieder: function mitglieder() {
      var _a;

      var members = compact(map((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.mitglieder, function (m) {
        return behandlerStore.getters.map[m];
      }));

      if (this.showAll || members.length <= maxMembersToShow) {
        return members;
      }

      return take(members, maxMembersToShow);
    },
    showMore: function showMore() {
      var _a, _b, _c, _d, _e, _f;

      var tooMany = (((_c = (_b = (_a = this.params) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.mitglieder) === null || _c === void 0 ? void 0 : _c.length) || 0) > maxMembersToShow;

      if (!tooMany) {
        return '';
      }

      var diff = (((_f = (_e = (_d = this.params) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.mitglieder) === null || _f === void 0 ? void 0 : _f.length) || 0) - maxMembersToShow;
      return this.showAll ? "Zeige ".concat(diff, " weniger") : "Zeige ".concat(diff, " mehr");
    },
    addableBehandler: function addableBehandler() {
      var _this = this;

      return chain(behandlerStore.getters.values).filter(function (b) {
        var _a;

        return !((_a = _this.params.data) === null || _a === void 0 ? void 0 : _a.mitglieder.includes(b.extid));
      }).orderBy([function (b) {
        return b.veraltet;
      }, function (b) {
        return b.displayText;
      }], ['asc', 'asc']).value();
    },
    isCustom: function isCustom() {
      var _a;

      return !!((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.isCustom);
    }
  },
  methods: {
    removeTeamMitglied: function removeTeamMitglied(item) {
      var _a;

      console.log('removeTeamMitglied', (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.id, item.extid);

      if (this.params.data) {
        editSettingsStore.commit.removeTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    },
    addTeamMitglied: function addTeamMitglied(item) {
      if (this.params.data) {
        editSettingsStore.commit.addTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    }
  }
});