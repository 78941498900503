import { metricsApi } from '@/services/data';
import { IImportProtocolComplete } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';

export interface ImportStore {
  importProtocols: { [id: string]: { protocol: IImportProtocolComplete | null; isLoading: boolean; error?: string } };
}

const initialState: ImportStore = {
  importProtocols: {},
};

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => initialState,
  getters: {},
  mutations: {
    setImportProtocols(
      state,
      importProtocol: {
        [id: string]: { protocol: IImportProtocolComplete | null; isLoading: boolean; error?: string };
      },
    ) {
      for (const id in importProtocol) {
        if (Object.prototype.hasOwnProperty.call(importProtocol, id)) {
          if (!state.importProtocols[id]) {
            Vue.set(state.importProtocols, id, {});
            state.importProtocols[id] = { protocol: null, isLoading: false };
          }

          // Now update the specific protocol for the given id
          state.importProtocols[id] = importProtocol[id];
        }
      }
    },
  },
  actions: {
    async getProtocol(context, importId: string) {
      const { state, commit, getters } = rootActionContext(context);
      try {
        if (!state.importProtocols[importId]?.protocol) {
          commit.setImportProtocols({ [importId]: { ...state.importProtocols[importId], isLoading: true } });
          const importProtocol = await metricsApi.main.importProtocol(importId);
          if (importProtocol) {
            const protocol = {
              [importId]: { protocol: importProtocol, isLoading: false },
            };
            commit.setImportProtocols(protocol);
          } else {
            commit.setImportProtocols({
              [importId]: { protocol: null, isLoading: false, error: 'No data returned' },
            });
          }
          console.log('importStore::protocol fetched', state.importProtocols[importId]);
        } else {
          console.log('importStore::protocol already fetched', state.importProtocols[importId]);
        }
      } catch (error: any) {
        commit.setImportProtocols({
          ...state.importProtocols,
          [importId]: { protocol: null, isLoading: false, error: error.message },
        });
      }
    },
  },
});

export const importStore = store;
