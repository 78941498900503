import _, { keyBy } from 'lodash';
import { metricsApi } from '@/services/data';
import { ITeam, IBehandler, TeamTypes, UserPrivacyLevel } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { clientStateStore } from '@/state/clientStateStore';
import { behandlerStore } from '@/state/behandlerStore';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/unbound-method
const { store, rootActionContext } = createDirectStore({
  state: () => ({
    map: {} as { [id: string]: ITeam },
    teamsCache: [] as ITeam[],
    teamsKomplettCache: [] as ITeam[],
  }),
  getters: {
    map(state) {
      return state.map;
    },
    teams(state) {
      console.log('teams', state.teamsCache);
      return state.teamsCache;
    },
  },
  mutations: {
    setMap(state, m: { [id: string]: ITeam }) {
      state.map = m;
    },
    setTeamsCache(state, cache: ITeam[]) {
      state.teamsCache = cache;
    },
    setTeamsKomplettCache(state, cache: ITeam[]) {
      state.teamsKomplettCache = cache;
    },
    clearCache(state) {
      console.log('teamservice clear cache');
      state.teamsCache = [];
      state.teamsKomplettCache = [];
      state.map = {};
    },
  },
  actions: {
    async getData(context) {
      const { commit } = rootActionContext(context);

      let teamsCache = await metricsApi.staticData.getTeams({ za: true, pzr: true });
      if (clientStateStore.state.privacyLevel !== UserPrivacyLevel.NONE) {
        console.log('using fake teams data for sensitive data');
        teamsCache = fakeTeams(teamsCache, behandlerStore.state.map);
      }
      commit.setTeamsCache(teamsCache);

      let teamsKomplettCache = await metricsApi.staticData.getTeams({ za: true, pzr: true, rest: true });
      // commit.setTeamsKomplettCache(teamsKomplettCache);

      let m = keyBy(teamsKomplettCache, t => t.id);
      commit.setMap(m);

      console.log(`teams service getdata end`, teamsCache);
    },
  },
});

export const teamsStore = store;

function fakeTeams(teams: ITeam[], behandlerMap: { [id: string]: IBehandler }): ITeam[] {
  return _(teams)
    .map(t => {
      if ([TeamTypes.AUTOTEAM, TeamTypes.PRAXIS, TeamTypes.ALLE, TeamTypes.UNUSED].includes(t.typ)) {
        return t;
      }
      let firstMember = _.first(t.mitglieder);
      // @ts-ignore
      t.name = firstMember ? behandlerMap[firstMember]?.displayText : 'Team';
      return t;
    })
    .value();
}
