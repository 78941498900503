import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import { BehandlerTyp, PZRType, ZAType } from '@rose/types';
import { behandlerStore } from '@/state/behandlerStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  name: 'ZaPzrCell',
  data: function data() {
    return {
      params: {},
      values: {},
      id: null,
      behType: null,
      zaOptions: [{
        value: ZAType.partner,
        name: 'Inhaber/Partner'
      }, {
        value: ZAType.angestellt,
        name: 'Angestellter Zahnarzt'
      }, {
        value: ZAType.assistent,
        name: 'Vorbereitungsassistent'
      }],
      pzrOptions: [{
        value: PZRType.zfa,
        name: 'ZMF/ZFA'
      }, {
        value: PZRType.zmpzmf,
        name: 'ZMP'
      }, {
        value: PZRType.dh,
        name: 'DH'
      }]
    };
  },
  computed: {
    topLeistungen: function topLeistungen() {
      var _a;

      return this.params.data && ((_a = behandlerStore.getters.map[this.params.data]) === null || _a === void 0 ? void 0 : _a.topLeistungen);
    },
    behandlerType: {
      get: function get() {
        var _a, _b, _c, _d, _e, _f, _g;

        if (this.params.data && ((_b = (_a = editSettingsStore.getters.behandlerSettings) === null || _a === void 0 ? void 0 : _a[this.params.data]) === null || _b === void 0 ? void 0 : _b.zahnarzt) && !((_c = editSettingsStore.getters.behandlerSettings[this.params.data]) === null || _c === void 0 ? void 0 : _c.pzr)) {
          return BehandlerTyp.ZA;
        }

        if (this.params.data && !((_e = (_d = editSettingsStore.getters.behandlerSettings) === null || _d === void 0 ? void 0 : _d[this.params.data]) === null || _e === void 0 ? void 0 : _e.zahnarzt) && ((_g = (_f = editSettingsStore.getters.behandlerSettings) === null || _f === void 0 ? void 0 : _f[this.params.data]) === null || _g === void 0 ? void 0 : _g.pzr)) {
          return BehandlerTyp.PZR;
        }

        return null;
      },
      set: function set(value) {
        if (value === BehandlerTyp.ZA && this.params.data) {
          editSettingsStore.commit.updateBehandlerInfo({
            id: this.params.data,
            value: {
              zahnarzt: true,
              pzr: false
            }
          });
        }

        if (value === BehandlerTyp.PZR && this.params.data) {
          editSettingsStore.commit.updateBehandlerInfo({
            id: this.params.data,
            value: {
              zahnarzt: false,
              pzr: true
            }
          });
        }
      }
    },
    selectedPosition: {
      get: function get() {
        var _a, _b, _c, _d;

        if (this.behandlerType === BehandlerTyp.ZA) {
          return this.params.data && ((_b = (_a = editSettingsStore.getters.behandlerSettings) === null || _a === void 0 ? void 0 : _a[this.params.data]) === null || _b === void 0 ? void 0 : _b.zahnarztTyp);
        }

        if (this.behandlerType === BehandlerTyp.PZR) {
          return this.params.data && ((_d = (_c = editSettingsStore.getters.behandlerSettings) === null || _c === void 0 ? void 0 : _c[this.params.data]) === null || _d === void 0 ? void 0 : _d.pzrTyp);
        }

        return undefined;
      },
      set: function set(value) {
        if (this.behandlerType === BehandlerTyp.ZA && this.params.data) {
          editSettingsStore.commit.updateBehandlerInfoTyp({
            id: this.params.data,
            value: {
              zahnarztTyp: value,
              pzrTyp: undefined
            }
          });
        }

        if (this.behandlerType === BehandlerTyp.PZR && this.params.data) {
          editSettingsStore.commit.updateBehandlerInfoTyp({
            id: this.params.data,
            value: {
              zahnarztTyp: undefined,
              pzrTyp: value
            }
          });
        }
      }
    }
  },
  methods: {
    getAutoConfiguredClass: function getAutoConfiguredClass() {
      var _a, _b;

      return this.params.data && ((_b = (_a = editSettingsStore.getters.behandlerSettings) === null || _a === void 0 ? void 0 : _a[this.params.data]) === null || _b === void 0 ? void 0 : _b.grund) ? 'auto-configured' : '';
    }
  }
});