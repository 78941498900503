import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent } from 'vue';
import { skipHideOptionList } from '@rose/common-ui';
import { hasAlarm } from '@rose/base';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {
      params: {},
      skipHideOption: false,
      skipAlarm: false,
      reiter: null,
      feld: null
    };
  },
  computed: {
    hiddenField: function hiddenField() {
      var _a;

      if (editSettingsStore.getters.heuteHideFields && this.feld && this.reiter) {
        return (_a = editSettingsStore.getters.heuteHideFields[this.reiter]) === null || _a === void 0 ? void 0 : _a[this.feld];
      }

      return false;
    },
    counterValue: function counterValue() {
      var _a, _b, _c;

      if (((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) && ((_b = this.params.colDef) === null || _b === void 0 ? void 0 : _b.field)) {
        var counter = (_c = editSettingsStore.getters.alarme[this.params.data.feldKey]) === null || _c === void 0 ? void 0 : _c[this.params.colDef.field];
        return counter <= -1 ? '-' : counter;
      }

      return undefined;
    },
    counterColor: function counterColor() {
      if (this.counterValue > 0) {
        return 'primary lighten-1';
      }

      if (this.counterValue === 0) {
        return 'warning';
      }

      if (this.counterValue === '-' && !this.hiddenField) {
        return 'error';
      }

      if (this.counterValue === '-' && this.hiddenField) {
        return 'none';
      }

      return 'primary';
    }
  },
  mounted: function mounted() {
    var _a, _b, _c, _d;

    if (((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) && ((_b = this.params.colDef) === null || _b === void 0 ? void 0 : _b.field)) {
      this.reiter = (_c = this.params.colDef) === null || _c === void 0 ? void 0 : _c.field;
      this.feld = (_d = this.params.data) === null || _d === void 0 ? void 0 : _d.feldKey;
      this.skipHideOption = skipHideOptionList.includes(this.feld);
      this.skipAlarm = !hasAlarm(this.reiter, this.feld); // console.log(
      //   `countercell (reiter,field,skiphide,skipalarm)`,
      //   this.reiter,
      //   this.feld,
      //   this.skipHideOption,
      //   this.skipAlarm,
      // );
    }
  },
  methods: {
    itemClicked: function itemClicked() {
      if (this.feld) {
        editSettingsStore.commit.toggleHeuteHideField({
          alarmField: this.reiter,
          alarmType: this.feld
        });
      }
    },
    decrease: function decrease() {
      if (this.counterValue >= -1) {
        editSettingsStore.commit.decreaseAlarm({
          alarmField: this.feld,
          alarmType: this.reiter
        });
      }
    },
    increase: function increase() {
      editSettingsStore.commit.increaseAlarm({
        alarmField: this.feld,
        alarmType: this.reiter
      });
    }
  }
});