import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { INewUser, ADMIN_LEVEL, IUIUser, IUIUserInfo } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { keyBy, chain, filter, toLower } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

export interface UsersStore {
  users: { [id: string]: IUIUser };
  userInfos: { [id: string]: IUIUserInfo };
  userFilter: string | null;
  showModal: boolean;
  showDeleteDialog: boolean;
  duplicateUser: boolean;
  selectedUser: IUIUser | undefined | null;
  isFetching: boolean;
}

const initialState: UsersStore = {
  users: {},
  userInfos: {},
  userFilter: null,
  showModal: false,
  showDeleteDialog: false,
  duplicateUser: false,
  selectedUser: undefined,
  isFetching: false,
};

Vue.use(Vuex);

export function resolveUserName(userEmail: string) {
  let user = usersStore.getters.usersCollection[userEmail];
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return userEmail;
}

const { store, rootActionContext } = createDirectStore({
  state: () => initialState,
  getters: {
    users(state) {
      let c = chain(state.users).values();
      if (state.userFilter) {
        const rgx = new RegExp(state.userFilter, 'i');
        c = c.filter(u => rgx.test(u.firstName) || rgx.test(u.lastName) || rgx.test(u.email));
      }
      return c.value();
    },
    usersCount(state): number {
      return Object.keys(state.users).length;
    },
    usersCollection(state) {
      return state.users;
    },
    userMails(state) {
      return chain(state.users).keys().map(toLower).value();
    },
    userNameByEmail(state) {
      const userEmail = authStore.getters.userEmail;
      const user = state.userInfos[userEmail];
      return user ? `${user.firstName} ${user.lastName}` : userEmail;
    },
    userFilter(state) {
      return state.userFilter;
    },
    showModal(state) {
      return state.showModal;
    },
    showDeleteDialog(state) {
      return state.showDeleteDialog;
    },
    selectedUser(state) {
      return state.selectedUser;
    },
    duplicateUser(state) {
      return state.duplicateUser;
    },
    hasRoseUser(state) {
      return filter(Object.values(state.users), u => u.adminLevel === ADMIN_LEVEL.ROSE_TEAM).length > 0;
    },
  },
  mutations: {
    setUserInfos(state, users: { [id: string]: IUIUserInfo }) {
      state.userInfos = users;
    },
    setUsers(state, users: { [id: string]: IUIUser }) {
      state.users = users;
    },
    setUserFilter(state, userFilter: string | null) {
      state.userFilter = userFilter;
    },
    setShowModal(state, show: boolean) {
      state.showModal = show;
    },
    setShowDeleteDialog(state, show: boolean) {
      state.showDeleteDialog = show;
    },
    setSelectedUser(state, user: IUIUser | null) {
      state.selectedUser = user;
    },
    setDuplicateUser(state, duplicate: boolean) {
      state.duplicateUser = duplicate;
    },
    duplicateUser(state, user: IUIUser) {
      const newUser: INewUser = {
        email: '',
        firstName: '',
        lastName: '',
        telephone: '',
        extids: user.extids || [],
        ipfiltering: user.ipfiltering,
        notificationSettings: user.notificationSettings || {
          emails: {
            main: {
              active: false,
            },
          },
        },
        tags: user.tags,
        viewSpecificBehandlerAccess: user.viewSpecificBehandlerAccess,
        views: user.views,
        adminLevel: user.adminLevel,
        mainclientid: user.mainclientid,
        clients: user.clients,
        isExternApiOnly: false,
        hide: false,
        clientsAccessRights: [],
        privacylevel: user.privacylevel,
      };
      state.selectedUser = newUser as unknown as IUIUser;
      console.log('duplicate User', state.selectedUser);
    },
    setIsFetching(state, isFetching: boolean) {
      state.isFetching = isFetching;
    },
  },
  actions: {
    async getData(context) {
      const { commit } = rootActionContext(context);

      const users = await metricsApi.auth.usersInfo();
      const userInfosCollection = keyBy(users, u => u.email);

      commit.setUserInfos(userInfosCollection);
    },
    async getAllUserData(context) {
      const { commit } = rootActionContext(context);

      const users = await metricsApi.auth.users();
      const usersCollection = keyBy(users, u => u.email);

      commit.setUsers(usersCollection);
    },
    async createUser(context, data: Partial<INewUser>) {
      const { dispatch } = rootActionContext(context);

      try {
        await metricsApi.auth.createUser(data);
        await dispatch.getData();
        await dispatch.getAllUserData();
        await authStore.dispatch.notifySuccess('Benutzer erfolgreich erstellt');
        return true;
      } catch (e) {
        await authStore.dispatch.notifyHttpError(e);
      }
    },
    async updateUser(context, data: Partial<INewUser>) {
      const { dispatch } = rootActionContext(context);

      try {
        const user = await metricsApi.auth.updateUser(data);
        await dispatch.getData();
        // TODO: load only updated user from server
        await dispatch.getAllUserData();
        await authStore.dispatch.notifySuccess('Benutzer erfolgreich aktualisiert');
        return user;
      } catch (e) {
        await authStore.dispatch.notifyHttpError(e);
      }
    },
    async updateUserEmail(context, data: { userMail: string; newUserMail: string }) {
      console.log('user mail updated', data);
      try {
        return await metricsApi.auth.updateUserEmail(data.userMail, data.newUserMail);
      } catch (e) {
        void authStore.dispatch.notifyHttpError(e);
      }
    },
    async resetUserPassword(context, data: { email: string }) {
      try {
        await metricsApi.auth.resetPassword(data.email);
        await authStore.dispatch.notifySuccess('E-Mail zum Zurücksetzen des Passworts wurde versendet');
      } catch (e) {
        await authStore.dispatch.notifyHttpError(e);
      }
    },
    async deleteUser(context, data: { email: string }) {
      const { dispatch } = rootActionContext(context);

      try {
        await metricsApi.auth.deleteUser(data.email);
        await dispatch.getData();
        await dispatch.getAllUserData();
        await authStore.dispatch.notifySuccess('Benutzer erfolgreich gelöscht');
      } catch (e) {
        await authStore.dispatch.notifyHttpError(e);
      }
    },
  },
});

export const usersStore = store;
