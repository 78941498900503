import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {
      params: {},
      menu: false,
      pickedColor: ''
    };
  },
  computed: {
    color: {
      get: function get() {
        return this.params.data.farbe || '#000000';
      },
      set: function set(value) {
        this.pickedColor = value;
      }
    },
    noColor: function noColor() {
      return !this.params.data.farbe;
    }
  },
  methods: {
    resetColor: function resetColor() {
      this.menu = false;
      this.pickedColor = this.color;
    },
    setColor: function setColor() {
      editSettingsStore.commit.setTeamColor({
        team: this.params.data,
        color: this.pickedColor
      });
      this.menu = false;
    },
    itemClicked: function itemClicked(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
});