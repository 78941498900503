import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    checkColor: function checkColor() {
      var _a;

      return ((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.colorized) ? 'success' : 'primary';
    },
    timesColor: function timesColor() {
      var _a;

      return ((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.colorized) ? 'error' : 'primary';
    }
  }
});