import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    behandler: {
      type: Object,
      required: false,
      "default": function _default() {
        return {};
      }
    },
    isDeletable: {
      type: Boolean,
      "default": false
    },
    isAddable: {
      type: Boolean,
      "default": false
    },
    isAutoconfigured: {
      type: Boolean,
      "default": false
    },
    manuallyConfiguredReasons: {
      type: String,
      "default": function _default() {
        return '';
      }
    },
    inTable: {
      type: Boolean,
      "default": false
    },
    outlined: {
      type: Boolean,
      "default": false
    },
    xtraSmall: {
      type: Boolean,
      "default": false
    }
  },
  computed: {},
  methods: {
    deleteBehandler: function deleteBehandler() {
      this.$emit('deleteBehandler', this.behandler);
    },
    addBehandler: function addBehandler() {
      this.$emit('addBehandler', this.behandler);
    }
  }
});