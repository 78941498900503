import { defineComponent } from 'vue';
import StichwortChip from '@/components/StichwortChip.vue';
export default defineComponent({
  components: {
    StichwortChip: StichwortChip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    stichwort: function stichwort() {
      return this.params.value;
    }
  }
});