import { VueConstructor, h } from 'vue-demi';

/**
 * Component that allows rendering a vitual dom node.
 * Can be used to render slots of a component more dynamically.
 * render-v-node(:node='$slots.default[0]')
 */
export function registerRenderVNodeComponent(vueInstance: VueConstructor) {
  vueInstance.component('render-v-node', {
    props: {
      node: { type: [Object, Array], required: true },
      tag: { type: String, default: 'div' },
      options: {
        type: Object,
        default: () => {},
      },
    },
    render: function () {
      let nodes = this.node;
      if (!Array.isArray(nodes)) {
        nodes = [nodes];
      }
      return h(this.tag, this.options, nodes);
    },
  });
}
