import { defineComponent } from 'vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    item: {
      get: function get() {
        return this.params.value.value;
      },
      set: function set(value) {
        this.params.context.clicked({
          key: this.params.value.key,
          value: value
        });
      }
    },
    buttons: function buttons() {
      return this.params.context.buttons;
    }
  }
});