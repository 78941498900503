import { defineComponent } from 'vue';
import { markdownify } from '@rose/common-ui';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      "default": 'auto'
    },
    iconClass: {
      type: String,
      "default": undefined
    }
  },
  computed: {
    tooltipText: function tooltipText() {
      var md = markdownify(this.text); // console.log('md help', this.text, md);

      return md;
    }
  }
});