import { defineComponent } from 'vue';
import { IMfaType } from '@rose/types';
export default defineComponent({
  data: function data() {
    return {
      params: {},
      IMfaType: IMfaType
    };
  },
  computed: {
    mfaType: function mfaType() {
      var _a;

      return (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.mfatype;
    },
    mfaEnforced: function mfaEnforced() {
      var _a;

      return (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.mfaenforced;
    }
  },
  moorunted: function moorunted() {},
  methods: {}
});