import "core-js/modules/es.array.some.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { rechnungenStore } from '@/state/rechnungenStore';
import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    showExtendedSummaryRow: function showExtendedSummaryRow() {
      return rechnungenStore.getters.showExtendedSummaryRow;
    },
    filterActive: function filterActive() {
      var _a, _b;

      if (!((_b = (_a = this.params) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.spezialFilter)) {
        return false;
      }

      var activeFilterKeys = rechnungenStore.getters.activeFilterKeys;
      return this.params.data.spezialFilter.some(function (filterKey) {
        return activeFilterKeys.includes(filterKey);
      });
    }
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    // this.cellValue = this.getValueToDisplay(this.params);
    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      this.params = params;
    }
  }
});