import _defineProperty from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.index-of.js";
import { defineComponent } from 'vue';
import { WoAnbietenTyp } from '@rose/types';
import { map } from 'lodash';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    var _woAnbietenMap;

    return {
      params: {},
      woAnbietenMap: (_woAnbietenMap = {}, _defineProperty(_woAnbietenMap, WoAnbietenTyp.GKV_PKV, 'Bei GKV und PKV'), _defineProperty(_woAnbietenMap, WoAnbietenTyp.PKV, 'Nur bei PKV'), _defineProperty(_woAnbietenMap, WoAnbietenTyp.GKV, 'Nur bei GKV'), _woAnbietenMap)
    };
  },
  computed: {
    id: function id() {
      var _a;

      return (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.id;
    },
    availableLeistungen: function availableLeistungen() {
      return editSettingsStore.getters.availableLeistungen;
    },
    availableWoAnbieten: function availableWoAnbieten() {
      var _this = this;

      var _a, _b, _c;

      return map((_c = (_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.konfiguration) === null || _b === void 0 ? void 0 : _b.woAnbieten) === null || _c === void 0 ? void 0 : _c.auswahl, function (value) {
        return {
          name: _this.woAnbietenMap[value],
          value: value
        };
      });
    },
    availableTriggerleistungen: function availableTriggerleistungen() {
      var _a, _b, _c;

      return map((_c = (_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.konfiguration) === null || _b === void 0 ? void 0 : _b.triggerLeistungen) === null || _c === void 0 ? void 0 : _c["default"], function (value) {
        return {
          name: value,
          value: value
        };
      });
    },
    konfiguration: function konfiguration() {
      var _a;

      return (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.konfiguration;
    },
    fehlendeLeistung: {
      get: function get() {
        var _a, _b;

        return (_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.fehlendeLeistung;
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              fehlendeLeistung: value
            }
          });
        }
      }
    },
    woAnbieten: {
      get: function get() {
        var _a, _b, _c, _d, _e;

        return ((_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.woAnbieten) || ((_e = (_d = (_c = this.params.data) === null || _c === void 0 ? void 0 : _c.konfiguration) === null || _d === void 0 ? void 0 : _d.woAnbieten) === null || _e === void 0 ? void 0 : _e["default"]);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              woAnbieten: value
            }
          });
        }
      }
    },
    triggerLeistungen: {
      get: function get() {
        var _a, _b, _c, _d, _e;

        return ((_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.triggerLeistungen) || ((_e = (_d = (_c = this.params.data) === null || _c === void 0 ? void 0 : _c.konfiguration) === null || _d === void 0 ? void 0 : _d.triggerLeistungen) === null || _e === void 0 ? void 0 : _e["default"]);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              triggerLeistungen: value
            }
          });
        }
      }
    },
    minAlter: {
      get: function get() {
        var _a, _b, _c, _d;

        return ((_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.minAlter) || ((_d = (_c = this.params.data) === null || _c === void 0 ? void 0 : _c.konfiguration) === null || _d === void 0 ? void 0 : _d.minAlter);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              minAlter: value
            }
          });
        }
      }
    }
  },
  methods: {
    filterWithGroups: function filterWithGroups(item, queryText, itemText) {
      return Object.prototype.hasOwnProperty.call(item, 'header') || itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    deleteMinAlter: function deleteMinAlter() {
      if (this.params.data) {
        editSettingsStore.commit.deleteMinAlter({
          kategorie: this.params.data.kategorie,
          id: this.params.data.id
        });
      }
    }
  }
});