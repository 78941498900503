import { metricsApi } from '@/services/data';
import { ISettings } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { isEmpty } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

export interface SettingsState {
  settings: ISettings;
}

const initialState: SettingsState = {
  settings: {} as ISettings,
};

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  strict: process.env.NODE_ENV !== 'production',
  state: () => initialState,
  getters: {
    isZ1(state) {
      return state.settings.pvs === 'z1';
    },
    isCharly(state) {
      return state.settings.pvs === 'charly';
    },
  },
  mutations: {
    setSettings(state, settings: ISettings) {
      state.settings = settings;
    },
  },
  actions: {
    async init(context) {
      const { commit, state } = rootActionContext(context);
      // TODO: CHANGE! here and in BehandlerTeams.vue
      const settings = await metricsApi.settings.getSettings(true);

      if (isEmpty(state.settings)) {
        console.log('initializing settingsState', settings);
      } else {
        console.log('reset settingsState', settings);
      }

      commit.setSettings(settings);
    },
  },
});

export const settingsStore = store;
