import { defineComponent } from 'vue';
import StichwortChip from '@/components/StichwortChip.vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
export default defineComponent({
  components: {
    StichwortChip: StichwortChip
  },
  data: function data() {
    return {
      params: {},
      id: ''
    };
  },
  computed: {
    regelSetting: {
      get: function get() {
        var _a, _b;

        return (_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.ignorierteStichworte;
      },
      set: function set(items) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              ignorierteStichworte: items
            }
          });
        }
      }
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore.state.params.stichworte;
    }
  }
});