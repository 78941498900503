import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
export default defineComponent({
  name: 'BehandlerCell',
  components: {
    BehandlerChip: BehandlerChip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    behandler: function behandler() {
      return this.params.value;
    }
  }
});