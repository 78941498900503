import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  methods: {
    deleteItem: function deleteItem() {
      this.params.context.clicked({
        row: this.params.data,
        column: this.params.value
      });
    }
  }
});