import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    isEditable: function isEditable() {
      var _a;

      return !!((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.isEditable);
    },
    icons: function icons() {
      return this.params.value;
    }
  },
  methods: {
    changedIconTitle: function changedIconTitle(title) {
      console.log('changedIconTitle', title);
      this.params.context.itemChanged({
        id: this.params.data.id,
        title: title
      });
    }
  }
});