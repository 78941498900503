import { assign, chain, compact, difference, isEmpty, keyBy, keys, map, size, sortBy, take } from 'lodash';
import { UserPrivacyLevel } from '../../../types';
import { isLocalhost } from '../config';
import { CommonLoggerService } from './logger.service';

export type RunAfterAugmentCallback = (extids: string[], caller: any) => Promise<void>;

/**
 * ein resolver holt infos live aus der praxis, die wir beio uns nicht in der datenbank haben
 *
 * der prozess geht so: der augment function werden objekte übergeben, per extractDataObjects werden daraus objecte extrahiert. mit extractIds werden dann aus diesen objekten ids geholt mit denene man im server zusatzinfos anfragen kann.
 *
 * für datensätze vom typ TAUGMENT mittels einer schlüssel ID zusatzinformationen vom server vom typ TINFO, mit denen dann die TAUGMENT datensätze erweitert werden. dabei kümmert sich dieser base resolver um das laden, caching, blacklisting von fehlerhaften items etc.
 */
export abstract class BaseResolverService<TAUGMENT, TDATAOBJECT, TINFO, TOPTIONS = {}> {
  private infoCache: { [extid: string]: TINFO } = {};
  private blacklist: { [extid: string]: number } = {}; // remember how often we where unsuccessful retrieving an info
  private timeout: any | undefined;
  protected abstract name: string;
  protected maxRetries = 10;
  protected fasterRetryTimeOutInSeconds = 0.5;
  protected retryTimeOutInSeconds = 5;
  protected verboseLogging = false;
  protected logger = new CommonLoggerService();
  protected fakeData = UserPrivacyLevel.NONE;

  public clearCache() {
    this.infoCache = {};
    console.log(`cleared resolver cache of ${this.name}`);
  }

  public augment(entries: TAUGMENT[], runAfterAugmenting?: RunAfterAugmentCallback, options?: TOPTIONS) {
    this.stop();
    // empty blacklist to get a fresh start
    this.blacklist = {};
    return this.augmentInfos(entries, runAfterAugmenting, options);
  }

  public stop() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  /**
   * this is used to extract dataobjects from an object to augment
   * @param entry the items to augment
   */
  public abstract extractDataObjects(entry: TAUGMENT, options?: TOPTIONS): TDATAOBJECT[];

  /**
   * this is used to extract a unique id from a data-object
   * @param entry the items to augment
   */
  public abstract extractId(entry: TDATAOBJECT, options?: TOPTIONS): string | undefined;

  /**
   * this loads the info element with the resolveinfo from the server
   * @param ids list of ids to get resolved infos for
   */
  public abstract loadInfo(ids: string[], options?: TOPTIONS): Promise<TINFO[]>;

  /**
   * this fakes the info element with some random data
   * @param dobjs data objects
   */
  public abstract fakeInfo(dobjs: TDATAOBJECT[], options?: TOPTIONS): Promise<TINFO[]>;

  /**
   * this blurs the info element with xxx
   * @param dobjs data objects
   */
  public abstract blurInfo(dobjs: TDATAOBJECT[], options?: TOPTIONS): Promise<TINFO[]>;

  /**
   * for item caching we need a key to extract from the resolve info item
   * @param item unique key for a resolve info item
   */
  public abstract extractInfoKey(item: TINFO, options?: TOPTIONS): string | undefined;

  /**
   * set data from the resolveinfo item to the entry, it should only return really changed ids
   * @param entry the entry missing the resolveinfo
   * @param resolveInfoMap map with resolve info
   */
  public abstract setResolvedInfo(
    entry: TAUGMENT,
    getResolvedInfo: (infoKey: string) => TINFO,
    options?: TOPTIONS,
  ): { changedIds: string[]; failedIds: string[] };

  /**
   * sometime it is necessary to have a different cache id than fetchid use this for that
   * @param cacheId is used fo caching
   */
  public extractFetchId(cacheId: string) {
    return cacheId;
  }

  /**
   * this method tries to fetch termin comments directly from collector. infos will be cached and first tried to resolve from cache.
   * if there are infos which could not be resolved it is retried
   * @param entries entries with kommentar fields
   * @param retry is this a retry (only touch server if cache misses some names)
   */
  private async augmentInfos(entries: TAUGMENT[], runAfterAugmenting?: RunAfterAugmentCallback, options?: TOPTIONS) {
    // fetch infos
    if (!isEmpty(entries)) {
      try {
        let dataObjects: TDATAOBJECT[] = chain(entries)
          .flatMap((e: TAUGMENT) => this.extractDataObjects(e, options))
          .compact()
          .uniqBy((o: TDATAOBJECT) => this.extractId(o, options))
          .value();
        let ids: string[] = chain(dataObjects)
          .flatMap((e: TDATAOBJECT) => this.extractId(e, options))
          .compact()
          .uniq()
          .value();
        // donot touch server in first try, use cache, if cache has entries
        let cachedIds: string[] = keys(this.infoCache);
        let blackListIds: string[] = chain(this.blacklist)
          .toPairs()
          .filter((x: any) => x[1] > this.maxRetries)
          .map((x: any) => x[0])
          .value();
        const limit: number = (options as any)?.limit || 10000;
        const diffIds = difference(ids, cachedIds, blackListIds);
        const limited = limit < size(diffIds);
        let fetchIds: string[] = take(diffIds, limit);
        if (!isEmpty(fetchIds)) {
          const idsToFetch = map(fetchIds, id => this.extractFetchId(id));
          let infos: TINFO[] = [];
          switch (this.fakeData) {
            case UserPrivacyLevel.NONE:
              infos = await this.loadInfo(idsToFetch, options);
              break;
            case UserPrivacyLevel.FAKENAMES:
              infos = await this.fakeInfo(dataObjects, options);
              break;
            case UserPrivacyLevel.BLURNAMES:
            case UserPrivacyLevel.BLURNAMESBUTNOTBEHANDLER:
              infos = await this.blurInfo(dataObjects, options);
              break;
            default:
              break;
          }
          if (isLocalhost && size(idsToFetch) > size(infos) && this.verboseLogging) {
            this.logger.warn(
              `${this.name}: got less infos than we wanted: ${size(idsToFetch)} > ${size(infos)}`,
              fetchIds,
              infos,
            );
          }
          if (!isEmpty(infos)) {
            assign(
              this.infoCache,
              keyBy(infos, (i: TINFO) => this.extractInfoKey(i, options)),
            );
          }
        }
        // console.log(
        //   `${this.name}: cache`,
        //   _(this.infoCache)
        //     .keys()
        //     .size(),
        //   this.infoCache,
        // );
        let emptyInfosIds = [];
        let changedIds: string[] = [];
        for (let e of entries) {
          // TODO use global cache
          let result = this.setResolvedInfo(e, id => this.infoCache[id], options);
          changedIds = [...changedIds, ...result.changedIds];
          // when an item could be changed no need to have it on the blacklist anymore
          for (const id of compact(result.changedIds)) {
            delete this.blacklist[id];
          }
          for (const id of result.failedIds) {
            if (id) {
              // first retry should be immediate
              if (!this.blacklist[id]) {
                this.blacklist[id] = 0;
              }
              if (this.blacklist[id] < this.maxRetries) {
                emptyInfosIds.push(id);
              }
              this.blacklist[id]++;
            }
          }
        }
        // console.log('resolver result', emptyInfosCount, this.blacklist);
        if (runAfterAugmenting && !isEmpty(changedIds)) {
          await runAfterAugmenting(chain(changedIds).uniq().sort().value(), this.name);
        }
        if (!isEmpty(emptyInfosIds)) {
          if (this.verboseLogging) {
            this.logger.warn(
              `${this.name}: got ${size(emptyInfosIds)} missing info to resolve, try again in ${
                this.retryTimeOutInSeconds
              }s, tries ${chain(this.blacklist).values().max().value()}/${this.maxRetries}`,
              sortBy(emptyInfosIds),
              this.blacklist,
            );
          }
          void this.tryToAugmentAgain(entries, runAfterAugmenting, options, limited);
        }
      } catch (e) {
        this.logger.info(`error in resolver: ${this.name}`, e);
        // first retry should be immediate
        void this.tryToAugmentAgain(entries, runAfterAugmenting, options);
      }
    }
  }

  private tryToAugmentAgain(
    entries: TAUGMENT[],
    runAfterAugmenting?: RunAfterAugmentCallback,
    options?: TOPTIONS,
    tryFaster = false,
  ) {
    try {
      let timeout = 1000 * (tryFaster ? this.fasterRetryTimeOutInSeconds : this.retryTimeOutInSeconds);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => this.augmentInfos(entries, runAfterAugmenting, options), timeout);
    } catch (err) {
      this.logger.error(err, `error while tryToAugmentAgain`);
    }
  }
}
