import { chain, map } from 'lodash';
import { BaseResolverService } from './base-resolver.service';
import { handleTMK } from './base-resolver.misc';
import { ITerminInfoContainer, ITerminMitKommentar, ITerminResolveInfo } from '../../../types';
import { fakerDe } from '../fakerDe';

export abstract class CommonFolgeTermineTerminInfoResolverService extends BaseResolverService<
  ITerminInfoContainer,
  ITerminMitKommentar,
  ITerminResolveInfo
> {
  public name = 'termine ft';
  public maxRetries = 5;

  extractDataObjects(entry: ITerminInfoContainer): ITerminMitKommentar[] {
    return chain(entry.folgetermine).compact().uniqBy(this.extractId).value();
  }

  public extractId(entry: ITerminMitKommentar) {
    return entry.extid;
  }

  public extractInfoKey(item: ITerminResolveInfo) {
    return item.extid;
  }

  public fakeInfo(dobjs: ITerminMitKommentar[]): Promise<ITerminResolveInfo[]> {
    return Promise.resolve(
      map(dobjs, o => {
        let pn: ITerminResolveInfo = {
          extid: o.extid || '',
          kommentar: fakerDe.lorem.sentence(10),
        };
        return pn;
      }),
    );
  }

  public blurInfo(dobjs: ITerminMitKommentar[]): Promise<ITerminResolveInfo[]> {
    return this.fakeInfo(dobjs);
  }

  public setResolvedInfo(e: ITerminInfoContainer, getResolvedInfo: (infoKey: string) => ITerminResolveInfo) {
    let result = { changedIds: [] as string[], failedIds: [] as string[] };
    if (e.folgetermine) {
      for (const tmk of e.folgetermine) {
        const tmkResult = handleTMK(tmk, getResolvedInfo, result);
        if (tmkResult) {
          result.changedIds.push(tmkResult);
        }
      }
    }
    return result;
  }
}
