import { SpezialGroupFilterConfig } from '@/types/spezialFilters';
import { every, keys, some, uniqBy, filter, chain, find } from 'lodash';
import { ITerminMitKommentar, TerminStatusType } from '@rose/types';
import { roseDayjs } from '../../../base';

export class SpezialFilterHandler<T extends { patid: string }> {
  constructor(private filterGroups: SpezialGroupFilterConfig<T>) {}

  filterEntries(entries: T[]) {
    let filtered = entries;
    for (const active of this.activeFilters()) {
      filtered = filtered.filter((l: T) => active?.rowFilter(l));
    }
    return filtered;
  }

  activeFilters() {
    return [
      ...chain(this.filterGroups)
        .values()
        .filter(filterGroup => filterGroup.active)
        .map(filterGroup => filterGroup.filters.find(f => f.key === filterGroup.selected))
        .value(),
    ];
  }

  toggleFilterGroup(f: string) {
    this.filterGroups[f].active = !this.filterGroups[f].active;
  }

  setFilterGroupActive({ filter: f, active }: { filter: string; active: boolean }) {
    this.filterGroups[f].active = active;
  }

  filterGroupSelectedChanged(opts: { filterGroup: string; filterValue?: string }) {
    if (opts.filterValue) {
      // check if filterValue is already selected and active
      if (
        this.filterGroups[opts.filterGroup].selected === opts.filterValue &&
        this.filterGroups[opts.filterGroup].active
      ) {
        // deselect filter
        this.setFilterGroupActive({ filter: opts.filterGroup, active: false });
      } else {
        // activate and select filter
        this.setFilterGroupActive({ filter: opts.filterGroup, active: true });
        this.filterGroups[opts.filterGroup].selected = opts.filterValue;
      }
    } else {
      this.setFilterGroupActive({ filter: opts.filterGroup, active: true });
    }
  }

  filterGroupsWithCounts(entries: T[]) {
    for (const fk of keys(this.filterGroups)) {
      let atLeastOneFilterWithEntries = false;

      let filterGroup = this.filterGroups[fk];
      filterGroup.filters.forEach(subFilter => {
        let fr = filter(entries, subFilter.rowFilter);
        subFilter.entryCount = fr.length;
        subFilter.patientCount = uniqBy(fr, rd => rd.patid).length;

        if (subFilter.entryCount > 0) {
          atLeastOneFilterWithEntries = true;
        }
      });

      let selectedOrLastFilter = filterGroup.filters.find(f => f.key === filterGroup.selected);
      filterGroup.entryCount = selectedOrLastFilter?.entryCount;
      filterGroup.patientCount = selectedOrLastFilter?.patientCount;
      filterGroup.atLeastOneFilterWithEntries = atLeastOneFilterWithEntries;
    }

    return { ...this.filterGroups };
  }

  activeFilterKeys() {
    return Object.entries(this.filterGroups)
      .filter(([key, f]) => f.active)
      .map(([key, f]) => {
        const subFilterKey = find(f.filters, subFilter => subFilter.key === f.selected)?.key;
        return `${key}.${subFilterKey}`;
      });
  }

  setFilterStateFromViewState(filterString: string) {
    try {
      let filterArray = filterString.split(',');

      let groupFilterKeys = filterArray.filter(key => key.includes('.'));

      groupFilterKeys.forEach(key => {
        let [groupKey, subKey] = key.split('.');
        this.filterGroupSelectedChanged({ filterGroup: groupKey, filterValue: subKey });
        this.setFilterGroupActive({ filter: groupKey, active: true });
      });
    } catch (e) {}
  }

  getViewStateString() {
    let activeFilterKeys = this.activeFilterKeys();
    if (activeFilterKeys.length) {
      return activeFilterKeys.join(',');
    } else {
      return false;
    }
  }
}

// Termin spezial filter
export const wvlFilter: SpezialGroupFilterConfig<{ wiedervorlage: any }> = {
  frist: {
    name: 'Frist',
    active: false,
    selected: 'frist',
    filters: [
      {
        key: 'frist',
        name: 'Frist gesetzt',
        rowFilter: rd => rd.wiedervorlage,
        help: 'Leistungen für die eine Frist gesetzt wurde, unabhängig davon ob das Fälligkeitsdatum in der Zukunft liegt oder bereits überschritten worden ist',
      },
      {
        key: 'fristabgelaufen',
        name: 'Frist fällig',
        rowFilter: rd => rd.wiedervorlage && roseDayjs(rd.wiedervorlage).isBefore(roseDayjs()),
        help: 'Leistungen für die eine Frist gesetzt wurde und deren Fälligkeitsdatum überschritten worden ist',
      },
    ],
  },
};

export const folgeTermineFilter: SpezialGroupFilterConfig<{ folgetermine?: ITerminMitKommentar[] }> = {
  folgetermine: {
    name: 'Folgetermine',
    selected: 'ohneFolgetermine',
    active: false,
    filters: [
      {
        key: 'ohneFolgetermine',
        name: 'Ohne Folgetermine',
        rowFilter: rd => !some(rd.folgetermine, ft => ft.status !== TerminStatusType.OFFEN),
      },
      {
        key: 'mitFolgtermine',
        name: 'Mit Folgetermine',
        rowFilter: rd => some(rd.folgetermine, ft => ft.status !== TerminStatusType.OFFEN),
      },
      {
        key: 'baldtermine',
        name: 'Termin binnen 7 Tagen',
        rowFilter: rd =>
          some(
            rd.folgetermine,
            ft =>
              ft.status !== TerminStatusType.OFFEN &&
              roseDayjs(ft.tag).isBetween(roseDayjs(), roseDayjs().add(7, 'days')),
          ),
      },
      {
        key: 'keinterminin4wo',
        name: 'Kein Termin binnen 4 Wochen',
        rowFilter: rd =>
          every(
            rd.folgetermine,
            ft => ft.status !== TerminStatusType.OFFEN && roseDayjs(ft.tag).isAfter(roseDayjs().add(4, 'weeks')),
          ),
      },
      {
        key: 'keinterminin8wo',
        name: 'Kein Termin binnen 8 Wochen',
        rowFilter: rd =>
          every(
            rd.folgetermine,
            ft => ft.status !== TerminStatusType.OFFEN && roseDayjs(ft.tag).isAfter(roseDayjs().add(8, 'weeks')),
          ),
      },
      {
        key: 'keinterminin4wo',
        name: 'Kein Termin binnen 12 Wochen',
        rowFilter: rd =>
          every(
            rd.folgetermine,
            ft => ft.status !== TerminStatusType.OFFEN && roseDayjs(ft.tag).isAfter(roseDayjs().add(12, 'weeks')),
          ),
      },
      {
        key: 'ohneOffeneTermine',
        name: 'Ohne offene Termine',
        rowFilter: rd =>
          !some(rd.folgetermine, ft => ft.status !== TerminStatusType.OFFEN) &&
          !some(rd.folgetermine, ft => ft.status === TerminStatusType.OFFEN),
      },
      {
        key: 'mitOffenenTerminen',
        name: 'Mit offenen Terminen',
        rowFilter: rd =>
          !some(rd.folgetermine, ft => ft.status !== TerminStatusType.OFFEN) &&
          some(rd.folgetermine, ft => ft.status === TerminStatusType.OFFEN),
      },
    ],
  },
};
