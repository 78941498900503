import {
  IAbrechnungsVorschlag,
  IAbrechnungsVorschlagAblehnung,
  IAbrechnungsvorschlaegeQueryParams,
  IAbrechnungsvorschlaegeResult,
  IBemaQuartalResult,
  IAbrechnungsVorschlagAblehnungsMap,
  IAbrechnungsvorschlaegeResultScore,
} from '../../../../types';
import { RoseApi } from '../apiTypes';
import * as qs from 'qs';

export function createAbrechnungsvorschlaegeApi(api: RoseApi) {
  return {
    vorschlagAblehnen: (data: IAbrechnungsVorschlag) =>
      api.metrics.post<IAbrechnungsVorschlagAblehnung>(`/api/vorschlagablehnen`, data),
    vorschlagAblehnenId: (id: number) =>
      api.metrics.delete<IAbrechnungsVorschlagAblehnung>(`/api/vorschlagablehnen/${id}`),
    getAbrechnungsvorschlaege: (data: IAbrechnungsvorschlaegeQueryParams) =>
      api.metrics.get<IAbrechnungsvorschlaegeResult>(`/api/abrechnungsvorschlaege`, { query: data }),
    getIgnorierteVorschlaege: (patid: string, regel: string) =>
      api.metrics.get<IAbrechnungsVorschlagAblehnungsMap>(`/api/abgelehntevorschlaege/${patid}/${regel}`),
    getLatestBemaQuartal: () => api.metrics.get<IBemaQuartalResult>(`/api/abrechnungsvorschlaege/latestbemaquartal`),

    getAbrechnungsKPIs: (params: { from?: string; to?: string }) =>
      api.metrics.get<any>(`/api/abrechnung/kpis?${qs.stringify(params)}`),
  };
}
