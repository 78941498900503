import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    link: function link() {
      var _a;

      return (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.downloadLink;
    }
  }
});