import {
  IAnamneseArchivedResponse,
  IAnamneseInfos,
  IAnamnesePartialResponse,
  IAnamneseResponse,
  IAnamneseResponseDetails,
  IAnamneseResponseMetaData,
  IAnamneseTaskInfo,
  IAnamneseTaskSendParams,
  ICharlyAnamneseConfig,
  ICharlyPatientDetails,
  IQuestionnaire,
  IQuestionnaireGroup,
  IQuestionnaireQuestionAnswer,
  ITabletTaskInfoAnamnese,
  ITabletTaskWrapped,
  QuestionnaireInterpolationMap,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createAnamnesetApi(api: RoseApi) {
  return {
    // from r4c
    setCharlyAnamneseConfig: (charlyAnameseConfig: ICharlyAnamneseConfig) =>
      api.r4cInstance.post<any>(`/anamnese/setCharlyAnamneseConfig`, { charlyAnameseConfig }),
    updateCharlyAnamneseConfig: () => api.r4cInstance.post<any>(`/anamnese/updateCharlyAnamneseConfig`),
    assignAnamneseTask: (params: {
      cid: string;
      tabletToken: string;
      questionnaireId: string;
      anamneseInfos: IAnamneseInfos;
      start: Date;
    }) => api.r4cInstance.post<any>(`/anamnese/assignAnamneseTask`, params),
    shareAnamneseTaskPerMail: (params: {
      cid: string;
      email: string;
      questionnaireId: string;
      anamneseInfos: IAnamneseInfos;
      start: Date;
    }) => api.r4cInstance.post<any>(`/anamnese/shareAnamneseTaskPerMail`, params),
    // Tablet & Anamnese App
    saveAnamneseResponseTablet: (params: { tabletToken?: string; response: IAnamneseTaskSendParams }) =>
      api.r4cInstance.post<{ taskId: string }>(`/anamnese/saveAnamneseResponseTablet`, params),
    saveAnamneseResponseShared: (params: { sharedTaskId: string; response: IAnamneseTaskSendParams }) =>
      api.r4cInstance.post<{ taskId: string }>(`/anamnese/saveAnamneseResponseShared`, params),
    saveAnamneseResponseTakeover: (params: {
      takeoverSecret: string;
      taskId: string;
      response: IAnamneseTaskSendParams;
    }) => api.r4cInstance.post<{ taskId: string }>(`/anamnese/saveAnamneseResponseTakeover`, params),
    saveAnamneseResponsePublic: (params: {
      response: IAnamneseTaskSendParams;
      externalTaskInfos: IAnamneseTaskInfo;
      challenge: string;
      challengeSolution: string;
    }) => api.r4cDispatch.post<{ taskId: string }>(`/anamnese/saveAnamneseResponsePublic`, params), // r4cDispatch, because r4cDispatch created the challenge
    previewAnamneseDocument: (params: {
      questionnaire: IQuestionnaire;
      anamneseResponseDetails: IAnamneseResponseDetails;
      interpolationMap: QuestionnaireInterpolationMap;
      languageKey: string;
    }) =>
      api.r4cInstance.post<{ dataUrls: string[]; anamneseHtml?: string }>(`/anamnese/previewAnamneseDocument`, params),
    generateQuestionnairePrintVersion: (params: {
      questionnaire: IQuestionnaire;
      interpolationMap: QuestionnaireInterpolationMap;
      languageKey: string;
      anamneseResponseDetails?: IAnamneseResponseDetails;
    }) =>
      api.r4cInstance.post<{ html: string; pdfDataUrl: string }>(`/anamnese/generateQuestionnairePrintVersion`, params),
    // Review App
    getAnamneseResponse: (responseId: string | number) =>
      api.r4cInstance.post<{ response: IAnamneseResponse }>(`/anamnese/getAnamneseResponse`, {
        responseId,
      }),
    getAllAnamneseResponses: () =>
      api.r4cInstance.post<{ responses: IAnamnesePartialResponse[] }>(`/anamnese/getAllAnamneseResponses`),
    getQuestionnaireGroups: () =>
      api.r4cInstance.post<{ questionnaireGroups: IQuestionnaireGroup[] }>(`/anamnese/getQuestionnaireGroups`),
    createAnamneseTask: (params: { questionnaireId: string; patid: string }) =>
      api.r4cInstance.post<{ taskWrapped: ITabletTaskWrapped<ITabletTaskInfoAnamnese> }>(
        `/anamnese/createAnamneseTask`,
        params,
      ),
    getAnamneseResponseMetaData: (patid: string) =>
      api.r4cInstance.post<{
        responses: IAnamneseResponseMetaData[];
        archivedResponses: IAnamneseArchivedResponse[];
        totalResponseCount: number;
      }>(`/anamnese/getAnamneseResponseMetaData`, {
        patid,
      }),
    reviewedAnamneseResponse: (params: {
      responseId: string;
      charlyUser: string;
      assignPatid?: number | string;
      reviewModifiedPatientDetails?: ICharlyPatientDetails;
    }) => api.r4cInstance.post<any>(`/anamnese/reviewedAnamneseResponse`, params),
    manualAnamneseResponse: (params: { questionnaireId: string; patid: string; charlyUser: string }) =>
      api.r4cInstance.post<any>(`/anamnese/manualAnamneseResponse`, params),
    deleteAnamneseResponse: (responseId: string) =>
      api.r4cInstance.delete<any>(`/anamnese/deleteAnamneseResponse`, {
        responseId,
      }),

    assignTabletPrefillAnamneseTask: (params: { taskInfo: ITabletTaskInfoAnamnese; tabletToken: string }) =>
      api.r4cInstance.post<{ taskId: string }>(`/anamnese/assignTabletPrefillAnamneseTask`, params),
    sharePrefillAnamneseTaskPerMail: (params: { taskInfo: ITabletTaskInfoAnamnese; email: string }) =>
      api.r4cInstance.post<{ taskId: string }>(`/anamnese/sharePrefillAnamneseTaskPerMail`, params),

    takeOverTabletTask: (params: { takeoverSecret: string }) =>
      api.r4cInstance.post<{ cid: string; tasksWrapped: ITabletTaskWrapped[] }>(`/anamnese/takeOverTabletTask`, params),

    translateStrings: (params: { texts: string[]; targetLang: string }) =>
      api.r4cInstance.post<{ [key: string]: string }>(`/anamnese/translateStrings`, params),

    savePrefillAnswers: (params: {
      tabletToken: string;
      prefillAnswers: Record<string, IQuestionnaireQuestionAnswer>;
    }) => api.r4cInstance.post<{ [key: string]: string }>(`/anamnese/savePrefillAnswers`, params),

    findCharlyPatientsForResponse: (params: { firstName: string; lastName: string; birthday: string }) =>
      api.r4cInstance.post<
        {
          details: ICharlyPatientDetails;
          created: string;
          lastaction: string;
          lastappointment: string;
          modified: string;
          patid: number;
          veraltet: boolean;
        }[]
      >(`/anamnese/findCharlyPatientsForResponse`, params),

    findCharlyPatientById: (params: { patid: number }) =>
      api.r4cInstance.post<ICharlyPatientDetails>(`/anamnese/findCharlyPatientById`, params),

    // external questionnaire flow without patient context
    createExternQuestionnaireFlow: (questionnaireId: string) =>
      api.r4cDispatch.get<{
        taskWrapped: ITabletTaskWrapped<ITabletTaskInfoAnamnese>;
        r4chost: string;
        cid: string;
        challenge: string;
      }>(`/anamnese/createExternQuestionnaireFlow/${questionnaireId}`),
  };
}
