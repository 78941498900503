import {
  IPAREreignisGruppeExtended,
  PAREreignisGruppeTyp,
  PAREreignisGruppeZeitTyp,
  IPAREreignisGruppe,
  PAREreignisTyp,
  IPAREreignisLeistungen,
} from '@rose/types';
import { chain, some, filter, isEmpty, toInteger } from 'lodash';

export function filterForUPTView(ereignisGruppen: IPAREreignisGruppeExtended<Date>[]): IPAREreignisGruppeExtended[] {
  const itemsFiltered = chain(ereignisGruppen)
    .filter(g =>
      [
        PAREreignisGruppeTyp.REEVALUATION_AIT,
        PAREreignisGruppeTyp.UPT_I,
        PAREreignisGruppeTyp.UPT_II,
        PAREreignisGruppeTyp.UPT_III,
        PAREreignisGruppeTyp.UPT_IV,
        PAREreignisGruppeTyp.UPT_V,
        PAREreignisGruppeTyp.UPT_VI,
        PAREreignisGruppeTyp.UPT_VII,
      ].includes(g.group),
    )
    .value();

  return chain(itemsFiltered)
    .reverse()
    .map((g, idx, collection) => {
      if (!hasInterestingEreignisse(g)) {
        // prüfen ob vorgänger ereignisse hat
        if (idx < collection.length - 1) {
          // vorgänger hat ereignisse
          g.hide = !hasInterestingEreignisse(collection[idx + 1]);
          return g;
        }
        g.hide = true;
        return g;
      }
      // prüfen ob aktuelle gruppe in der zukuft liegt, keine vergangeheit oder aktuelles intervall wegfiltern
      if (g.zeit !== PAREreignisGruppeZeitTyp.ZUKUNFT) {
        g.hide = false;
        return g;
      }
      g.hide = false;
      return g;
    })
    .reverse()
    .value();
}

export function filterForPARView(ereignisGruppen: IPAREreignisGruppeExtended<Date>[]): IPAREreignisGruppeExtended[] {
  return filter(
    ereignisGruppen,
    g =>
      [
        PAREreignisGruppeTyp.VORBEHANDLUNG,
        PAREreignisGruppeTyp.HAUPTBEHANDLUNG,
        PAREreignisGruppeTyp.NACHBEHANDLUNG_BEVA,
      ].includes(g.group) && !isEmpty(g.ereignisse),
  );
}

export function filterForCPTView(ereignisGruppen: IPAREreignisGruppeExtended<Date>[]): IPAREreignisGruppeExtended[] {
  return filter(
    ereignisGruppen,
    g =>
      [PAREreignisGruppeTyp.REEVALUATION_AIT, PAREreignisGruppeTyp.CPT, PAREreignisGruppeTyp.REEVALUATION_CPT].includes(
        g.group,
      ) && !isEmpty(g.ereignisse),
  );
}

export function parLeistungen(leistungen: IPAREreignisLeistungen): unknown {
  return chain(leistungen)
    .toPairs()
    .sortBy(([nummer]) => sorter(nummer))
    .map(([nummer, infos]) => (toInteger(infos.anzahl) > 1 ? `${infos.anzahl}x ${nummer}` : nummer))
    .join(', ')
    .value();
}

function hasInterestingEreignisse(g: IPAREreignisGruppe) {
  if (!g) {
    return false;
  }
  return some(
    g.ereignisse,
    e => ![PAREreignisTyp.UPTTERMINZEITMARKERSTART, PAREreignisTyp.UPTTERMINZEITMARKERENDE].includes(e.typ),
  );
}

function sorter(nummer: string): number {
  if (!nummer) {
    return 0;
  }
  if (nummer.startsWith('atg')) {
    return 1;
  }
  if (nummer.startsWith('mhu')) {
    return 2;
  }
  if (nummer.startsWith('ait')) {
    return 3;
  }
  if (nummer.startsWith('108')) {
    return 4;
  }
  if (nummer.startsWith('111')) {
    return 5;
  }
  if (nummer.startsWith('bev')) {
    return 6;
  }
  if (nummer.startsWith('upt')) {
    return 7;
  }
  return 10;
}
