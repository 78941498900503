/* eslint-disable @typescript-eslint/unbound-method */
import { createExtendedSummaryRow } from '@/helpers/rechnungen-summary';
import { folgeTermineFilter, SpezialFilterHandler } from '@/helpers/spezialFilterHandler';
import { metricsApi } from '@/services/data';
import { authStore } from '@/state/authStore';
import { clientStateStore } from '@/state/clientStateStore';
import { viewStateStore } from '@/state/viewStateStore';
import { createDirectStore } from 'direct-vuex';
import { filter, isEmpty, map } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { last30Days } from '@rose/common-ui';
import { roseDayjs } from '../../../base';
import { createSummaryRow } from '../../../common-ui/src/directImports/rechnungen.service';
import {
  IRechnungExtended,
  ITeam,
  RechnungSearchDateFilter,
  RechnungSearchStatusFilter,
  IRechnungRequestParams,
  IRechnungExtendedWithVeraltet,
  IRechnungExtendedWithVeraltetLeistungenFiltered,
} from '@rose/types';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    rechnungen: [] as IRechnungExtendedWithVeraltet[],
    rechnungenLoading: false,
    patientSearchInput: '',
    leistungSearchInput: '',
    statusFilter: RechnungSearchStatusFilter.Offen as RechnungSearchStatusFilter,
    dateFilter: RechnungSearchDateFilter.Alle,
    date: {
      startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
    },
    bagatellschwelle: 0,
    teamSelected: null as null | ITeam,
    leistungserbringerSelected: null as null | ITeam,
    showExtendedSummaryRow: false,
    summaryRow: [] as any[],
    extendedSummaryRow: [] as any[],
    spezialFilterHandler: new SpezialFilterHandler<IRechnungExtendedWithVeraltet>({
      ...folgeTermineFilter,
      ignoreinactive: {
        name: 'Veraltete Patienten',
        selected: 'active',
        active: false,
        filters: [
          {
            name: 'Veraltete Patienten',
            key: 'active',
            rowFilter: rechnung => rechnung.veraltet,
            help: 'Rechnungen von veralteten Patienten',
          },
        ],
      },
      miterlass: {
        name: 'Mit Erlass',
        selected: 'miterlass',
        active: false,
        filters: [
          {
            name: 'Mit Erlass',
            key: 'miterlass',
            rowFilter: rechnung => rechnung.erlassen > 0,
            help: 'Rechnungen mit Erlass',
          },
        ],
      },
      privatkasse: {
        name: 'Versicherung',
        selected: 'privat',
        active: false,
        filters: [
          {
            name: 'Private Patienten',
            key: 'privat',
            rowFilter: rechnung => rechnung.privat,
            help: 'Rechnungen von privat versicherten Patienten',
          },
          {
            name: 'Kasse Patienten',
            key: 'kasse',
            rowFilter: rechnung => !rechnung.privat,
            help: 'Rechnungen von gesetzlich versicherten Patienten',
          },
        ],
      },
      alter: {
        name: 'Alter',
        selected: 'erwachsene',
        active: false,
        filters: [
          {
            name: 'Kinder',
            key: 'kinder',
            rowFilter: rechnung => rechnung.alter < 7,
            help: 'Rechnungen von Patienten im Alter von 0-6 Jahren',
          },
          {
            name: 'Jugendliche',
            key: 'jugendliche',
            rowFilter: rechnung => rechnung.alter >= 7 && rechnung.alter < 18,
            help: 'Rechnungen von Patienten im Alter von 7-18 Jahren',
          },
          {
            name: 'Kinder und Jugendliche',
            key: 'kinderundjugendliche',
            rowFilter: rechnung => rechnung.alter < 18,
            help: 'Rechnungen von Patienten im Alter von 0-18 Jahren',
          },
          {
            name: 'Erwachsene',
            key: 'erwachsene',
            rowFilter: rechnung => rechnung.alter >= 18,
            help: 'Rechnungen von Patienten die 18 Jahre oder älter sind',
          },
        ],
      },
      zahlungsziel: {
        name: 'Zahlungsziel',
        selected: 'zahlungszielUeberschritten',
        active: false,
        filters: [
          {
            key: 'zahlungszielUeberschritten',
            name: 'Zahlungsziel überschritten',
            rowFilter: (rechnung: IRechnungExtended) =>
              (rechnung.bezahltdatum && roseDayjs(rechnung.bezahltdatum).isAfter(rechnung.faellig)) ||
              (!rechnung.bezahlt && rechnung.erlassen <= 0 && roseDayjs(rechnung.faellig).isBefore(new Date())),
            help: `Rechnungen die entweder zu spät bezahlt wurden oder die noch nicht bezahlt wurden und deren Zahlungsziel (Fällig) überschritten wurde`,
          },
          {
            key: 'zahlungszielZukunft',
            name: 'Zahlungsziel in der Zukunft',
            rowFilter: (rechnung: IRechnungExtended) => roseDayjs(rechnung.faellig).isAfter(new Date()),
            help: `Rechnungen mit einem Zahlungsziel (Fällig) in der Zukunft`,
          },
        ],
      },
      mahnung: {
        name: 'Mahnung',
        help: `Rechnungen die offen sind und im Mahnverfahren sind`,
        selected: 'mahnung',
        active: false,
        filters: [
          {
            key: 'mahnung',
            name: 'Mahnverfahren',
            help: 'Mahnverfahren',
            rowFilter: rechnung => rechnung.mahnstufe > 0,
          },
          {
            key: 'mahnung1',
            name: '1. Mahnung',
            help: '1. Mahnung',
            rowFilter: rechnung => rechnung.mahnstufe === 1,
          },
          {
            key: 'mahnung2',
            name: '2. Mahnung',
            help: '2. Mahnung',
            rowFilter: rechnung => rechnung.mahnstufe === 2,
          },
          {
            key: 'mahnung3',
            name: '3. Mahnung',
            help: '3. Mahnung',
            rowFilter: rechnung => rechnung.mahnstufe === 3,
          },
          {
            key: 'mahnung4',
            name: 'Anwalt',
            help: 'Anwalt',
            rowFilter: rechnung => rechnung.mahnstufe === 4,
          },
        ],
      },
      rz: {
        name: 'Rechenzentrum',
        help: `Rechenzentrum`,
        selected: 'rz',
        active: false,
        filters: [
          {
            key: 'rz',
            name: 'Rechenzentrum',
            help: 'RZ',
            rowFilter: rechnung => rechnung.rechenzentrum === true,
          },
          {
            key: 'norz',
            name: 'Kein Rechenzentrum',
            help: 'Kein RZ',
            rowFilter: rechnung => rechnung.rechenzentrum === false,
          },
        ],
      },
      realisierbar: {
        name: 'Rechenzentrum',
        selected: 'realisierbar',
        active: false,
        filters: [
          {
            key: 'realisierbar',
            name: 'Realisierbar',
            rowFilter: rechnung => {
              let verjahrtDatum = roseDayjs(rechnung.faellig).endOf('year').add(3, 'year');
              // noch nicht verjaehrt && noch rest
              return !verjahrtDatum.isBefore(roseDayjs()) && rechnung.rest > 0;
            },
          },
          {
            key: 'verjahrtJahresende',
            name: 'Verjährt Jahresende',
            rowFilter: rechnung => {
              let kritischesJahr = roseDayjs().get('year') - 3;
              return roseDayjs(rechnung.faellig).get('year') === kritischesJahr;
            },
          },
          {
            key: 'verjahrt',
            name: 'Verjährt',
            rowFilter: rechnung => {
              let verjahrtDatum = roseDayjs(rechnung.faellig).add(3, 'year').endOf('year');
              return verjahrtDatum.isBefore(roseDayjs());
            },
          },
        ],
      },
    }),
  }),
  getters: {
    rechnungen(state) {
      return state.rechnungen;
    },
    rechnungenFiltered(state): IRechnungExtendedWithVeraltetLeistungenFiltered[] {
      let filtered: IRechnungExtendedWithVeraltetLeistungenFiltered[] = state.rechnungen;
      filtered = filter(filtered, l =>
        `${l.name} ${l.vorname} ${l.patid}`.toLowerCase().includes(state.patientSearchInput),
      );
      if (state.leistungSearchInput) {
        const lrgx = new RegExp(state.leistungSearchInput, 'i');
        filtered = map(filtered, (r: IRechnungExtendedWithVeraltetLeistungenFiltered) => ({
          ...r,
          filteredLeistungen: filter(r.leistungen, l => lrgx.test(l.nummer)),
        }));
        filtered = filter(filtered, r => !isEmpty(r.filteredLeistungen));
      }
      for (const active of activeFilters()) {
        if (active) {
          filtered = filter(filtered, (l: IRechnungExtendedWithVeraltet) => !!active.rowFilter(l));
        }
      }
      return filtered;
    },
    rechnungenLoading(state) {
      return state.rechnungenLoading;
    },
    filterGroups(state) {
      return state.spezialFilterHandler.filterGroupsWithCounts(state.rechnungen);
    },
    activeFilterKeys(state) {
      return state.spezialFilterHandler.activeFilterKeys();
    },
    patientSearchInput(state) {
      return state.patientSearchInput;
    },
    leistungSearchInput(state) {
      return state.leistungSearchInput;
    },
    statusFilter(state) {
      return state.statusFilter;
    },
    dateFilter(state) {
      return state.dateFilter;
    },
    date(state) {
      return state.date;
    },
    bagatellschwelle(state) {
      return state.bagatellschwelle;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    leistungserbringerSelected(state) {
      return state.leistungserbringerSelected;
    },
    showExtendedSummaryRow(state) {
      return state.showExtendedSummaryRow;
    },
    summaryRow(state, getters) {
      return [createSummaryRow(getters.rechnungenFiltered, getters.showExtendedSummaryRow)];
    },
    extendedSummaryRow(state, getters) {
      const tmp = createExtendedSummaryRow(getters.showExtendedSummaryRow);
      tmp.forEach((row: any) => {
        if (row.rowFilter) {
          row.filterCount = getters.rechnungenFiltered.filter(row.rowFilter).length;
          row.count = getters.rechnungen.filter(row.rowFilter).length;
        }
      });
      return tmp;
    },
  },
  mutations: {
    setRechnungen(state, items: (IRechnungExtended & { veraltet: boolean })[]) {
      state.rechnungen = items;
    },
    setRechnungenLoading(state, bool: boolean) {
      state.rechnungenLoading = bool;
    },
    setPatientSearchInput(state, searchInput) {
      state.patientSearchInput = searchInput;
    },
    setLeistungSearchInput(state, searchInput) {
      state.leistungSearchInput = searchInput;
    },
    setStatusFiter(state, statusFilter: RechnungSearchStatusFilter) {
      if (
        state.dateFilter === RechnungSearchDateFilter.Alle &&
        (statusFilter === RechnungSearchStatusFilter.Alle || statusFilter === RechnungSearchStatusFilter.Bezahlt)
      ) {
        state.dateFilter = RechnungSearchDateFilter.Erstellt;
      }

      state.statusFilter = statusFilter;
    },
    setDateFilter(state, dateFilter: RechnungSearchDateFilter) {
      if (dateFilter === RechnungSearchDateFilter.Bezahlt) {
        state.statusFilter = RechnungSearchStatusFilter.Bezahlt;
      }
      state.dateFilter = dateFilter;
    },
    setDate(state, date) {
      state.date = date;
    },
    setBagatellschwelle(state, num: number) {
      state.bagatellschwelle = num;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setLeistungserbringerSelected(state, team) {
      state.leistungserbringerSelected = team;
    },
    setSummaryRow(state, summary) {
      state.summaryRow = summary;
    },
    setExtendedSummaryRow(state, summary) {
      state.extendedSummaryRow = summary;
    },
    switchExtendedSummaryRow(state) {
      state.showExtendedSummaryRow = !state.showExtendedSummaryRow;
    },
  },
  actions: {
    async getData(context) {
      const { state, dispatch, commit } = rootActionContext(context);

      commit.setRechnungenLoading(true);

      let queryParams: IRechnungRequestParams = {
        leistungserbringer: state.leistungserbringerSelected?.id,
        team: state.teamSelected?.id,
        status: state.statusFilter,
        bagatellschwelle: state.bagatellschwelle !== 0 ? state.bagatellschwelle : undefined,
      };

      if (state.dateFilter === RechnungSearchDateFilter.Erstellt) {
        queryParams = { ...queryParams, erstelltFrom: state.date?.startDate, erstelltTo: state.date?.endDate };
      }

      if (state.dateFilter === RechnungSearchDateFilter.Faellig) {
        queryParams = { ...queryParams, faelligFrom: state.date?.startDate, faelligTo: state.date?.endDate };
      }

      if (state.dateFilter === RechnungSearchDateFilter.Bezahlt) {
        queryParams = { ...queryParams, bezahltFrom: state.date?.startDate, bezahltTo: state.date?.endDate };
      }

      await viewStateStore.dispatch.removeFromViewState([
        'erstelltFrom',
        'erstelltTo',
        'faelligFrom',
        'faelligTo',
        'bezahltFrom',
        'bezahltTo',
      ]);
      await viewStateStore.dispatch.addToViewState((queryParams as any) || {});

      const rechnungenResult = await metricsApi.rechnungen.getRechnungen(queryParams);
      const rechnungen = rechnungenResult.rechnungen;
      await clientStateStore.getters.names.augment(rechnungen, undefined, { withPAR: true });
      await clientStateStore.getters.folgeterminInfo.augment(rechnungen);

      commit.setRechnungen(rechnungen as any);
      commit.setRechnungenLoading(false);
    },
    setPatientSearchInput(context, input: string) {
      const { commit } = rootActionContext(context);
      commit.setPatientSearchInput(input);
    },
    setLeistungSearchInput(context, input: string) {
      const { commit } = rootActionContext(context);
      commit.setLeistungSearchInput(input);
    },
    async setStatusFilter(context, statusFilter: RechnungSearchStatusFilter) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setStatusFiter(statusFilter);
      await dispatch.getData();
    },
    async setDateFilter(context, dateFilter: RechnungSearchDateFilter) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDateFilter(dateFilter);
      await dispatch.getData();
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDate(data);
      await dispatch.getData();
    },
    async setBagatellschwelle(context, num: number) {
      const { state, dispatch, commit } = rootActionContext(context);
      const needsUpdate = state.bagatellschwelle !== num;
      if (!needsUpdate) {
        return;
      }
      commit.setBagatellschwelle(num);
      await dispatch.getData();
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setTeamSelected(team);
      await dispatch.getData();
    },
    async setLeistungserbringerSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setLeistungserbringerSelected(team);
      await dispatch.getData();
    },
    async toggleFilterGroup(context, filterKey: string) {
      const { state, dispatch } = rootActionContext(context);
      state.spezialFilterHandler.toggleFilterGroup(filterKey);
      await dispatch.updateFilterViewState();
    },
    async filterGroupSelectedChanged(context, opts: { filterGroup: string; filterValue?: string }) {
      const { state, dispatch } = rootActionContext(context);
      state.spezialFilterHandler.filterGroupSelectedChanged(opts);
      await dispatch.updateFilterViewState();
    },
    async updateFilterViewState(context) {
      const { state } = rootActionContext(context);
      let viewStateString = state.spezialFilterHandler.getViewStateString();
      if (viewStateString) {
        await viewStateStore.dispatch.addToViewState({
          filters: viewStateString,
        });
      } else {
        await viewStateStore.dispatch.removeFromViewState(['filters']);
      }
    },
    setFilterStateFromViewState(context) {
      const { state } = rootActionContext(context);
      if (viewStateStore.getters.viewState.filters) {
        state.spezialFilterHandler.setFilterStateFromViewState(viewStateStore.getters.viewState.filters);
      }
    },
    async saveRechnungMahninfo(context, evt: any) {
      const kommentar = evt.data.mahnstoppKommentar;
      const datum = evt.data.mahnstoppDatum;
      const rechnung = evt.data.extid;
      const ersteller = authStore.getters.userEmail;
      await metricsApi.rechnungen.saveRechnungMahninfo({
        rechnung,
        kommentar,
        datum,
        ersteller,
      });
    },
    switchExtendedSummaryRow(context) {
      const { commit } = rootActionContext(context);
      commit.switchExtendedSummaryRow();
    },
  },
});

export const rechnungenStore = store;

function activeFilters() {
  return [
    ...Object.values(rechnungenStore.getters.filterGroups)
      .filter(filterGroup => filterGroup.active)
      .map(filterGroup => filterGroup.filters.find(f => f.key === filterGroup.selected)),
  ];
}
