import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import { BehandlerService } from '@/services/behandlerService';
import { defineComponent } from 'vue';
import { sumBy, chain } from 'lodash';
import PieChart from '../charts/PieChart.vue';
import { euroFilter } from '../../../../common-ui/src/filters';
import { chartColors } from '../../../../common-ui/src/colorScales';
export default defineComponent({
  components: {
    PieChart: PieChart
  },
  mixins: [euroFilter],
  data: function data() {
    return {
      params: null,
      behandler: [],
      behandlerText: '',
      behandlerCount: 0,
      chartVals: [],
      chartLabels: [],
      valueColumns: [],
      totals: null,
      maxBehandlerTooltipCount: 12,
      chartColors: chartColors,
      chartBackgrounds: null,
      chartStyles: {
        position: 'relative',
        width: '150px',
        height: '150px'
      },
      behandlerService: new BehandlerService(),
      typ: '-',
      colNames: {
        honorar: 'Honorar',
        material: 'Material',
        eigenlabor: 'Eigenlabor',
        eigenlabormaterial: 'Eigenlabor-Material',
        fremdlabor: 'Fremdlabor',
        betrag: 'Betrag',
        gesamt: 'Gesamt'
      }
    };
  },
  mounted: function mounted() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.setValues();
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh() {
      // set value into cell again
      this.setValues();
      return true;
    },
    setValues: function setValues() {
      var _this = this;

      if (!this.params.value) {
        return;
      }

      this.typ = this.params.typ;
      var totalColumn = 'gesamt';
      var chartColumn = 'honorar';

      if (this.typ === 'offeneleistungen') {
        this.valueColumns = ['honorar', 'material', 'eigenlabor', 'eigenlabormaterial', 'fremdlabor', 'gesamt'];
        totalColumn = 'gesamt';
        chartColumn = 'honorar';
      } else if (this.typ === 'rechnungen') {
        this.valueColumns = ['betrag'];
        totalColumn = 'betrag';
        chartColumn = 'betrag';
      }

      var honorardetails = this.params.value; // console.log('leb-cell', this.params, this.typ, honorardetails);

      this.behandlerCount = honorardetails.length; // calc totals

      this.totals = {};

      var _iterator = _createForOfIteratorHelper(this.valueColumns),
          _step;

      try {
        var _loop = function _loop() {
          var col = _step.value;
          _this.totals[col] = sumBy(honorardetails, function (hd) {
            return hd[col];
          });
        };

        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        } // only show maxBehandlerTooltipCount largest

      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      this.behandler = chain(honorardetails).orderBy(totalColumn, 'desc').take(this.maxBehandlerTooltipCount).value();
      this.behandler.forEach(function (entry) {
        return entry.displayName = _this.behandlerService.getProperty(entry.behandler);
      });
      this.behandlerText = this.leistungserbringer(this.behandler);
      this.chartVals = this.behandler.map(function (behandler) {
        return behandler[chartColumn];
      });
      this.chartLabels = this.behandler.map(function (behandler) {
        return behandler.displayName;
      });
      this.chartBackgrounds = this.behandler.map(function (behandler, index) {
        return behandler.farbe || _this.chartColors[index];
      });
    },
    leistungserbringer: function leistungserbringer(erbringer) {
      if (!erbringer) {
        return '';
      }

      if (this.behandlerCount === 0) {
        return '-';
      }

      var bcString = this.behandlerCount !== 1 ? "(".concat(this.behandlerCount, ") ") : '';
      var names = chain(erbringer).map(function (entry) {
        return entry.displayName;
      }).join(', ').value();
      return bcString + names;
    }
  }
});