import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { leistungenStore } from '@/state/leistungenStore';
import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: null
    };
  },
  computed: {
    hasComments: function hasComments() {
      var _this = this;

      var leistung = leistungenStore.getters.leistungen.find(function (l) {
        return l.patid === _this.params.data.patid;
      });
      return !!(leistung === null || leistung === void 0 ? void 0 : leistung.hasComments);
    }
  },
  methods: {}
});