import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    text: {
      get: function get() {
        return this.params.value.value;
      },
      set: function set(value) {
        this.params.context.clicked({
          key: this.params.value.key,
          value: value
        });
      }
    },
    label: function label() {
      return this.params.context.label;
    },
    key: function key() {
      return this.params.value.key;
    }
  },
  methods: {
    clicked: function clicked(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
});