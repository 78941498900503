import { metricsApi } from '@/services/data';
import { IBehandler, UserPrivacyLevel } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { first, keyBy, sortBy, chain, values } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { clientStateStore } from './clientStateStore';
import { faker } from '@faker-js/faker/locale/de';
import { fakeTitle } from '@rose/common-ui';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    map: {} as { [id: string]: IBehandler },
  }),
  getters: {
    map(state) {
      return state.map;
    },
    values(state): IBehandler[] {
      return values(state.map);
    },
    terminBehandler(state): IBehandler[] {
      return chain(state.map)
        .values()
        .filter(b => !b.veraltet && !!b.termine)
        .sortBy(b => b.name)
        .value();
    },
    zahnaerzte(state): IBehandler[] {
      return chain(state.map)
        .values()
        .filter(b => !b.veraltet && !!b.behandler && !!b.info?.zahnarzt)
        .sortBy(b => b.name)
        .value();
    },
    pzr(state): IBehandler[] {
      return chain(state.map)
        .values()
        .filter(b => !b.veraltet && !!b.behandler && !!b.info?.pzr)
        .sortBy(b => b.name)
        .value();
    },
    behandler(state): IBehandler[] {
      return chain(state.map)
        .values()
        .filter(b => !b.veraltet && !!b.behandler && !!(b.info?.pzr || b.info?.zahnarzt))
        .sortBy(b => b.name)
        .value();
    },
  },
  mutations: {
    setMap(state, map: { [id: string]: IBehandler }) {
      state.map = map;
    },
    clear(state) {
      console.log('behandler store clear');
      state.map = {};
    },
  },
  actions: {
    async load(context) {
      const { commit } = rootActionContext(context);

      const behandler: IBehandler[] = await metricsApi.staticData.getBehandler(true);
      if (clientStateStore.state.privacyLevel === UserPrivacyLevel.FAKENAMES) {
        console.log('using fake data for sensitive data');
        for (const b of behandler) {
          fakeBehandler(b);
        }
      }
      if (clientStateStore.state.privacyLevel === UserPrivacyLevel.BLURNAMES) {
        console.log('using blured data for sensitive data');
        for (const b of behandler) {
          blurBehandler(b);
        }
      }
      // update behandler service
      console.log(
        'Behandler',
        sortBy(behandler, b => b.extid),
      );

      const behandlerMap = keyBy(behandler, b => b.extid);
      commit.setMap(behandlerMap);
    },
  },
});

export const behandlerStore = store;

function fakeBehandler(behandler: IBehandler): void {
  if (behandler) {
    const title = fakeTitle(true);
    const name = faker.name.lastName();
    const vorname = faker.name.firstName();
    behandler.name = name;
    behandler.vorname = vorname;
    behandler.displayText = title ? `${title} ${name}, ${vorname}` : `${name}, ${vorname}`;
    behandler.kuerzel = `${first(name)}${first(vorname)}`;
  }
}

function blurBehandler(behandler: IBehandler): void {
  if (behandler) {
    const title = fakeTitle(true);
    const name = '******';
    const vorname = '******';
    behandler.name = name;
    behandler.vorname = vorname;
    behandler.displayText = title ? `${title} ${name}, ${vorname}` : `${name}, ${vorname}`;
    behandler.kuerzel = `${first(name)}${first(vorname)}`;
  }
}
