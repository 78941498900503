import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    condition: {
      get: function get() {
        var _a, _b;

        return (_b = editSettingsStore.getters.alarme[(_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey]) === null || _b === void 0 ? void 0 : _b.conditions;
      },
      set: function set(value) {
        var _a;

        if ((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.feldKey) {
          editSettingsStore.commit.setAlarmCondition({
            alarmField: this.params.data.feldKey,
            condition: value
          });
        }
      }
    },
    conditions: function conditions() {
      return this.params.value;
    }
  }
});