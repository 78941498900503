import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    termingruppe: function termingruppe() {
      return this.params.value;
    }
  }
});