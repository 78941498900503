import { isNil, isNumber } from 'lodash';
import { numeral } from '../numeral';

export const numeralFilter = {
  filters: {
    numeral(value: any, fractionDigits = 1, fallback = '-') {
      if (isNil(value)) {
        return fallback;
      }
      if (isNumber(value)) {
        value += 0.00001;
      }
      return numeral(value, { style: 'decimal', fractionDigits: fractionDigits });
    },
  },
};
