import { CommonNameResolverService } from '@rose/common-ui/src/services';
import { collectorApi } from './data';
import { IPatientNameResolverParams, UserPrivacyLevel } from '@rose/types';

export class NameResolverService extends CommonNameResolverService {
  async loadInfo(ids: string[], options: IPatientNameResolverParams) {
    return collectorApi.bot.resolveNames({ ids, withPAR: options.withPAR, withAddress: options.withAddress });
  }

  public setFakeData(privacyLevel: UserPrivacyLevel) {
    this.fakeData = privacyLevel;
  }
}
