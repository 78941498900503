import { defineComponent } from 'vue';
import OpenInCharly from '@/components/OpenInCharly.vue';
import { roseDayjs } from '../../../../base';
export default defineComponent({
  components: {
    OpenInCharly: OpenInCharly
  },
  filters: {
    date: function date(d) {
      return d && roseDayjs(d).format('DD.MM.YYYY');
    }
  },
  data: function data() {
    return {
      params: null,
      mahnungen: null,
      mahnstufe: null
    };
  },
  beforeMount: function beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // this.cellValue = this.getValueToDisplay(this.params);
    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      //   this.cellValue = this.getValueToDisplay(params);
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      var _a;

      this.mahnstufe = params.value;
      this.mahnungen = (_a = params.data) === null || _a === void 0 ? void 0 : _a.mahnungen;
    }
  }
});