import { anamneseI18nMessages } from './i18nAnamnese';
import { tabletI18nMessages } from './i18nTablet';
import { papoI18nMessages } from './i18nPapo';

let _i18nMessages = simpleMerge(anamneseI18nMessages, tabletI18nMessages);
_i18nMessages = simpleMerge(_i18nMessages, papoI18nMessages);
export const i18nMessages = _i18nMessages;

function simpleMerge(obj1: any, obj2: any): any {
  const result = { ...obj1 };

  for (const key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
        // If the value is an object and not an array, recursively merge it
        result[key] = simpleMerge(result[key] || {}, obj2[key]);
      } else {
        // Otherwise, assign the value to the result object
        result[key] = obj2[key];
      }
    }
  }

  return result;
}

export const LOCALE_KEY_TO_LANGUAGE_NAME = {
  de: '🇩🇪 Deutsch',
  en: '🇬🇧 English',
  it: '🇮🇹 Italiano',
  ru: '🇷🇺 Русский',
  ar: '🇦🇪 عربى',
  es: '🇪🇸 Español',
  fr: '🇫🇷 Français',
  ja: '🇯🇵 日本語',
  pl: '🇵🇱 Polskie',
  tr: '🇹🇷 Türk',
  fa: '🇮🇷 فارسی', // Farsi
  gr: '🇬🇷 Ελληνικά', // Griechisch
  nl: '🇳🇱 Nederlands', // Niederländisch
  po: '🇵🇹 Português', // Portugiesisch
  pt: '🇧🇷 Português (BR)', // Brasilianisches Portugiesisch
  rm: '🇨🇭 Romansh', // Rätoromanisch
  ch: '🇨🇳 中文', // Chinesisch
  ko: '🇰🇷 한국어', // Koreanisch
  ur: '🇵🇰 اردو', // Urdu
  af: '🇿🇦 Afrikaans', // Afrikaans
  al: '🇦🇱 Shqip', // Albanisch
  bo: '🇧🇴 Aymara', // Bolivianisch
  bu: '🇧🇬 Български', // Bulgarisch
  cz: '🇨🇿 Čeština', // Tschechisch
  dt: '🇩🇰 Dansk', // Dänisch
  hr: '🇭🇷 Hrvatski', // Kroatisch
  hu: '🇭🇺 Magyar', // Ungarisch
  kr: '🇰🇷 한국어', // Koreanisch (Wiederholung, möglicherweise entfernen)
  pr: '🇵🇷 Puertorriqueño', // Puerto-Ricanisch
  rs: '🇷🇸 Српски', // Serbisch
  sc: '🇸🇪 Svenska', // Schwedisch
  sw: '🇨🇭 Schwiizerdütsch', // Schweizerdeutsch
  th: '🇹🇭 ไทย', // Thai
  vi: '🇻🇳 Tiếng Việt', // Vietnamesisch
  vs: '🇪🇸 Valencià', // Valencianisch
  zh: '🇨🇳 中文 (简体)', // Vereinfachtes Chinesisch
  pb: '🇵🇾 Guarani', // Paraguayisch
  ro: '🇷🇴 Română', // Rumänisch
};

let warnedMissingTranslations: Set<string> = new Set();

export const vueI18nConfig = {
  messages: i18nMessages,
  locale: 'de',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,

  // This ensures that every translation warning is only logged once.
  missing: (locale: string, key: string) => {
    let cacheKey = `${locale}##${key}`;

    if (!warnedMissingTranslations.has(cacheKey)) {
      warnedMissingTranslations.add(cacheKey);
      console.warn(`Missing ${locale} translation for ${key}`);
    }
  },
  silentTranslationWarn: true,
  silentFallbackWarn: true,
};
