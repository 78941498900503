const host = location && location.host.split(':')[0];
const ROSE_STATUS_API_URL = 'https://status.rose.dental/api.json';
const LOCAL_STORAGE_ROSE_STATUS_API_KEY = 'ROSE_STATUS_API_STATUS';

let currentRoseApiStatus: null | {
  useFallback: boolean;
  fallback: {
    metrics: string;
    factoring: string;
    collector: string;
  };
} = null;

// safe guard for when process is not defined
if (typeof process === 'undefined') {
  // @ts-ignore
  window.process = { env: {} };
}

async function checkRoseApiStatusUpdate() {
  try {
    let status = await fetch(ROSE_STATUS_API_URL).then(a => a.json());

    // check status change
    let statusChanged = JSON.stringify(currentRoseApiStatus) !== JSON.stringify(status);

    if (statusChanged) {
      // api config changed persist and reload
      console.warn('Rose status has changed going to reload', status);
      localStorage.setItem(LOCAL_STORAGE_ROSE_STATUS_API_KEY, JSON.stringify(status));
      location.reload();
    }
  } catch (e) {
    console.warn('could not fetch rose status', e);
  }
}

function periodicallyCheckRoseStatus() {
  const interval = 1000 * 60 * 5; // 5 minutes

  void checkRoseApiStatusUpdate();
  setInterval(() => checkRoseApiStatusUpdate(), interval);
}

export enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  REVIEW = 'review',
  DEMO = 'demo',
  PROD = 'prod',
  DEVTUNNELS = 'devtunnels',
}

export let isDev = getEnvironment() === Environment.DEV;
export let isProd = getEnvironment() === Environment.PROD;
export let isStaging = getEnvironment() === Environment.STAGING;
export let isReview = getEnvironment() === Environment.REVIEW;
export let isDemo = getEnvironment() === Environment.DEMO;

if (isProd) {
  currentRoseApiStatus = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ROSE_STATUS_API_KEY) || '{}');
  periodicallyCheckRoseStatus();
}

function getDevtunnelsAddressByPort(port: number) {
  return host.replace(/-\d+/, `-${port}`);
}
export function getEnvironment(): Environment {
  if (host.includes('-staging') || host.includes('.staging')) {
    return Environment.STAGING;
  }

  if (host.includes('-review') || host.includes('.review')) {
    return Environment.REVIEW;
  }

  if (host.includes('devtunnels') || host.includes('devtunnels')) {
    return Environment.DEVTUNNELS;
  }

  if (host.includes('-demo') || host.includes('.demo')) {
    return Environment.DEMO;
  }

  if (host.includes('-prod') || host.includes('.prod')) {
    return Environment.PROD;
  }

  if (host.includes('localhost') || host.startsWith('192.168') || host === 'r4c') {
    return Environment.DEV;
  }

  return Environment.PROD;
}

export function getMetricsUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_METRICS_URL) {
    return process.env.VUE_APP_METRICS_URL;
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:4000';
  }

  if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
    return currentRoseApiStatus.fallback.metrics;
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://api.rose.dental',
    [Environment.STAGING]: 'https://api.staging.rose.dental',
    [Environment.REVIEW]: 'https://api.review.rose.dental',
    [Environment.DEMO]: 'https://api.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:4000',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4000),
  };

  return envToUrlMap[env];
}

export function getR4cApiUrl(r4chost: string): string {
  let currentEnv = getEnvironment();
  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_FACTORING_URL) {
    return process.env.VUE_APP_FACTORING_URL;
  }

  if (currentEnv === Environment.DEV || (currentEnv === Environment.DEMO && host.includes('localhost'))) {
    return `http://${r4chost}:5000`;
  }
  return `https://${r4chost}`;
}

export function getR4cDispatcherUrl(): string {
  const envToUrlMap = {
    [Environment.PROD]: `https://r4cdispatcher.rose.dental`,
    [Environment.STAGING]: 'https://factoring.staging.rose.dental',
    [Environment.REVIEW]: 'https://factoring.review.rose.dental',
    [Environment.DEMO]: `https://factoring.staging.rose.dental`,
    [Environment.DEV]: 'http://localhost:5000',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(5000),
  };

  const currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_FACTORING_URL) {
    return process.env.VUE_APP_FACTORING_URL;
  }

  // special case localhost-prod-dev: prod server, dev ui
  if (currentEnv === Environment.PROD && host.includes('localhost-prod-dev')) {
    return envToUrlMap[currentEnv];
  }

  // special case localhost-demo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:5000';
  }

  // special case staging
  if (currentEnv === Environment.DEMO && host.includes('staging')) {
    return 'https://factoring.staging.rose.dental';
  }

  if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
    return currentRoseApiStatus.fallback.factoring;
  }

  return envToUrlMap[env];
}

export function getR4cUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_R4C_URL) {
    return process.env.VUE_APP_R4C_URL;
  }

  // special case localhost-prod-dev
  if (currentEnv === Environment.PROD && host.includes('localhost-prod-dev')) {
    return 'http://localhost-prod-dev:8081';
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost-demo:8081';
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://r4c.rose.dental',
    [Environment.STAGING]: 'https://r4c.staging.rose.dental',
    [Environment.REVIEW]: 'https://r4c.review.rose.dental',
    [Environment.DEMO]: 'https://r4c.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:8081',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8081),
  };

  return envToUrlMap[currentEnv];
}

export function getDesktopR4cUrl(): string {
  let currentEnv = getEnvironment();

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost-demo:8084';
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://desktop-r4c.rose.dental',
    [Environment.STAGING]: 'https://desktop-r4c.staging.rose.dental',
    [Environment.REVIEW]: 'https://desktop-r4c.review.rose.dental',
    [Environment.DEMO]: 'https://desktop-r4c.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:8084',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8084),
  };

  return envToUrlMap[currentEnv];
}

export function getUiUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_UI_URL) {
    return process.env.VUE_APP_UI_URL;
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:4200';
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://ui.rose.dental',
    [Environment.STAGING]: 'https://ui.staging.rose.dental',
    [Environment.REVIEW]: 'https://ui.review.rose.dental',
    [Environment.DEMO]: 'https://ui.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:4200',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4200),
  };

  return envToUrlMap[currentEnv];
}

export function getVuiUrl(): string {
  let currentEnv = getEnvironment();

  // special case localhost-staging & localhost-prod
  if (currentEnv === Environment.STAGING && host.includes('localhost-staging')) {
    return 'http://localhost-staging:4201';
  }
  if (currentEnv === Environment.PROD && host.includes('localhost-prod')) {
    return 'http://localhost-prod:4201';
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://vui.rose.dental',
    [Environment.STAGING]: 'https://vui.staging.rose.dental',
    [Environment.REVIEW]: 'https://vui.review.rose.dental',
    [Environment.DEMO]: 'https://vui.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:4201',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(4201),
  };

  return envToUrlMap[currentEnv];
}

export function getOnboardingUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_ONBOARDING_URL) {
    return process.env.VUE_APP_ONBOARDING_URL;
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:8083';
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://onboarding.rose.dental',
    [Environment.STAGING]: 'https://onboarding.staging.rose.dental',
    [Environment.REVIEW]: 'https://onboarding.review.rose.dental',
    [Environment.DEMO]: 'https://onboarding.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:8083',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8083),
  };

  return envToUrlMap[currentEnv];
}

export function getPapoUrl(): string {
  let currentEnv = getEnvironment();

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:8085';
  }

  if (process.env.VUE_APP_PAPO_URL) {
    return process.env.VUE_APP_PAPO_URL;
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://patient.rose.dental',
    [Environment.STAGING]: 'https://patient.staging.rose.dental',
    [Environment.REVIEW]: 'https://patient.review.rose.dental',
    [Environment.DEMO]: 'https://patient.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:8085',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(8085),
  };

  return envToUrlMap[currentEnv];
}

export function getCollectorUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_COLLECTOR_URL) {
    return process.env.VUE_APP_COLLECTOR_URL;
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:3001';
  }

  if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
    return currentRoseApiStatus.fallback.collector;
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://collector.rose.dental',
    [Environment.STAGING]: 'https://collector.staging.rose.dental',
    [Environment.REVIEW]: 'https://collector.review.rose.dental',
    [Environment.DEMO]: 'https://collector.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:3001',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(3001),
  };

  return envToUrlMap[currentEnv];
}

export function getSaverUrl(): string {
  let currentEnv = getEnvironment();

  let envToUrlMap = {
    [Environment.PROD]: 'https://saver.rose.dental',
    [Environment.STAGING]: 'https://saver.staging.rose.dental',
    [Environment.REVIEW]: 'https://saver.review.rose.dental',
    [Environment.DEMO]: 'https://saver.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:3002',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(3002),
  };

  return envToUrlMap[currentEnv];
}

export function getDoctosyncUrl(): string {
  let currentEnv = getEnvironment();

  // special case: DEMO
  if (currentEnv === Environment.DEV && host.startsWith('192.168') && process.env.VUE_APP_COLLECTOR_URL) {
    return process.env.VUE_APP_COLLECTOR_URL;
  }

  // special case localhostdemo
  if (currentEnv === Environment.DEMO && host.includes('localhost-demo')) {
    return 'http://localhost:6001';
  }

  if (currentEnv === Environment.PROD && currentRoseApiStatus && currentRoseApiStatus.useFallback) {
    return currentRoseApiStatus.fallback.collector;
  }

  let envToUrlMap = {
    [Environment.PROD]: 'https://doctosync.rose.dental',
    [Environment.STAGING]: 'https://doctosync.staging.rose.dental',
    [Environment.REVIEW]: 'https://doctosync.review.rose.dental',
    [Environment.DEMO]: 'https://doctosync.staging.rose.dental',
    [Environment.DEV]: 'http://localhost:6001',
    [Environment.DEVTUNNELS]: getDevtunnelsAddressByPort(6001),
  };

  return envToUrlMap[currentEnv];
}

export function getStripePublicKey() {
  const live_stripe_public_key = 'pk_live_MhA2TvVuMG0wLQW9ffiV9Zn2';
  const test_stripe_public_key = 'pk_test_wNcMlRJ6VnwiVXAFyz3iGugV';

  if (getEnvironment() === Environment.PROD) {
    return live_stripe_public_key;
  }

  return test_stripe_public_key;
}

export let env = getEnvironment();
export let isLocalhost = window.location.host.indexOf('localhost') >= 0;

function toLocalStoreageWrapper(urlGetter: () => string, key: string) {
  return () => {
    const url = urlGetter();
    localStorage.setItem(key, url);
    return url;
  };
}

/**
 * @deprecated Use metricsUrl instead
 */
export let apiUrl = toLocalStoreageWrapper(getMetricsUrl, 'apiurl')();

export let metricsUrl = toLocalStoreageWrapper(getMetricsUrl, 'metricsurl')();
export let collectorUrl = getCollectorUrl();
export let saverUrl = getSaverUrl();
export const doctosyncUrl = getDoctosyncUrl();

export let vuiUrl = getVuiUrl();
export let uiUrl = getUiUrl();
export let papoUrl = getPapoUrl();
export let onboardingUrl = getOnboardingUrl();
export let desktopR4cUrl = getDesktopR4cUrl();
export let r4cUrl = getR4cUrl();
export let r4cDispatchUrl = getR4cDispatcherUrl();

export function getConf(r4chost: string) {
  return { env, metricsUrl, vuiUrl, r4cApiUrl: getR4cApiUrl(r4chost), collectorUrl, uiUrl, saverUrl };
}
