import "core-js/modules/web.timers.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { defineComponent } from 'vue';
import { roseDayjs } from '../../../base';
import { allRangesKeyValue } from '../dateRanges';
export default defineComponent({
  filters: {
    date: function date(val) {
      return val ? roseDayjs(val).format('DD.MM.YYYY') : '';
    }
  },
  props: {
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    dateEnabled: {
      type: Boolean
    },
    dateRange: {
      type: Object,
      "default": function _default() {
        return {
          startDate: null,
          endDate: null
        };
      }
    },
    singleDatePickerProp: {
      type: Boolean
    },
    hideRanges: {
      type: Boolean
    },
    autoOpen: {
      type: Boolean
    },
    hideCalendarIcon: {
      type: Boolean
    },
    opens: {
      "default": 'smart',
      type: String
    },
    prefixed: {
      type: Boolean
    },
    suffixed: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      initialized: false,
      // Date
      isOpened: false,
      ranges: null,
      singleDatePicker: false,
      timePicker: false,
      timePicker24Hour: false,
      showDropdowns: true,
      autoApply: true,
      showWeekNumbers: true,
      linkedCalendars: false,
      alwaysShowCalendars: true,
      appendToBody: true,
      closeOnEsc: true,
      locale: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: 'Anwenden',
        cancelLabel: 'Abbrechen',
        weekLabel: 'W',
        customRangeLabel: 'Benutzerdefinierten Bereich',
        daysOfWeek: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        firstDay: 1
      },
      // smart open positions
      elementRect: null
    };
  },
  computed: {
    opensSmart: function opensSmart() {
      if (this.opens === 'smart') {
        if (!this.elementRect) {
          return 'center';
        }

        var rectCenter = this.elementRect.left + this.elementRect.width / 2;
        var windowWidth = window.innerWidth;

        if (rectCenter < windowWidth / 3) {
          return 'right';
        } else if (rectCenter > windowWidth / 3 * 2) {
          return 'left';
        } else {
          return 'center';
        }
      }

      return this.opens;
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.init();

    if (this.autoOpen) {
      setTimeout(function () {
        _this.openPicker();
      });
    }
  },
  methods: {
    init: function init() {
      var _a;

      try {
        this.initialized = true;
        this.singleDatePicker = (_a = this.singleDatePickerProp) !== null && _a !== void 0 ? _a : false;
        this.ranges = this.hideRanges ? null : allRangesKeyValue;
      } catch (e) {
        this.initialized = true;
        console.error(e);
      }
    },
    openPicker: function openPicker(event) {
      this.isOpened = !this.isOpened; // @ts-ignore

      this.$refs.picker.togglePicker(this.isOpened);
      event === null || event === void 0 ? void 0 : event.stopPropagation();
    },
    dateUpdate: function dateUpdate(v) {
      console.log('event: update', Object.assign({}, v)); // eslint-disable-next-line vue/no-mutating-props

      this.dateRange.startDate = roseDayjs(v.startDate); // eslint-disable-next-line vue/no-mutating-props

      this.dateRange.endDate = roseDayjs(v.endDate);
      this.$emit('date-update', {
        startDate: this.dateRange.startDate.format('YYYY-MM-DD'),
        endDate: this.dateRange.endDate.format('YYYY-MM-DD')
      });
    },
    dateOpen: function dateOpen(open) {
      this.isOpened = open;
      console.log('event: open', open);

      if (this.opens === 'smart') {
        var hostElm = this.$refs.hostElm;

        if (hostElm) {
          this.elementRect = hostElm.getBoundingClientRect();
        } else {
          this.elementRect = null;
        }
      } // predefined ranges to the right


      setTimeout(function () {
        var calendar = document.querySelector('.daterangepicker.show-ranges');

        if (calendar) {
          var container = calendar.querySelector('.calendars');
          var ranges = container.querySelector('.ranges');
          container.removeChild(ranges);
          container.appendChild(ranges);
        }
      });
    },
    dateFormat: function dateFormat(classes, date) {
      var yesterday = new Date();
      var d1 = roseDayjs(date).format('isoDate');
      var d2 = roseDayjs(yesterday.setDate(yesterday.getDate() - 1), 'isoDate').toString();

      if (!classes.disabled) {
        classes.disabled = d1 === d2;
      }

      return classes;
    }
  }
});