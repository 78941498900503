import {
  IHVMPatientDetails,
  IHVMQueryParams,
  IHVMWestfalenLippeParams,
  IHVMResultNordrheinBehandler,
  IHVMResultNordrheinVerlauf,
  IHVMResultNordrhein,
  IHVMResultBayern,
  IHVMResultBayernBehandler,
  IHVMResultWestfalenLippe,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createHVMApi(api: RoseApi) {
  return {
    getHVMNordrhein: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNordrhein>(`/api/hvm/nordrhein`, {
        query: queryParams,
      }),
    getHVMNordrheinBehandler: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNordrheinBehandler>(`/api/hvm/nordrhein/behandler`, {
        query: queryParams,
      }),
    getHVMNordrheinVerlauf: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNordrheinVerlauf>(`/api/hvm/nordrhein/verlauf`, {
        query: queryParams,
      }),
    getHVMNordrheinPatientDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      part: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/nordrhein/patientdetails`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        partdetail,
        behandlerId,
      }),
    getHVMNordrhein2: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultNordrhein>(`/api/hvm/nordrhein2`, {
        query: queryParams,
      }),
    getHVMNordrheinDetails2: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      part: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/nordrhein2/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        partdetail,
        behandlerId,
      }),
    getHVMBayern: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultBayern>(`/api/hvm/bayern`, {
        query: queryParams,
      }),
    getHVMBayernBehandler: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultBayernBehandler>(`/api/hvm/bayern/behandler`, {
        query: queryParams,
      }),
    getHVMBayern2: (queryParams?: IHVMQueryParams) =>
      api.metrics.get<IHVMResultBayern>(`/api/hvm/bayern2`, {
        query: queryParams,
      }),

    getHVMBayernPatientDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      part: string,
      fallgruppe: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/bayern/patientdetails`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        fallgruppe,
        partdetail,
        behandlerId,
      }),
    getHVMBayern2PatientDetails: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      part: string,
      fallgruppe: string,
      partdetail: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/bayern2/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        part,
        fallgruppe,
        partdetail,
        behandlerId,
      }),
    getHVMWestfalenLippe: (params?: IHVMWestfalenLippeParams) =>
      api.metrics.post<IHVMResultWestfalenLippe>(`/api/hvm/westfalen-lippe`, params),
    getHVMWestfalenLippe2: (params?: IHVMWestfalenLippeParams) =>
      api.metrics.post<IHVMResultWestfalenLippe>(`/api/hvm/westfalen-lippe2`, params),
    getHVMWestfalenLippeDetails2: (
      von: string,
      bis: string,
      altervon: number,
      alterbis: number,
      ignorekzv: boolean,
      teil: string,
      row: string,
      fallgruppe: string,
      behandlerId?: string,
    ) =>
      api.metrics.post<IHVMPatientDetails>(`/api/hvm/westfalen-lippe2/details`, {
        von,
        bis,
        altervon,
        alterbis,
        ignorekzv,
        teil,
        row,
        fallgruppe,
        behandlerId,
      }),
  };
}
