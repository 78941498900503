import { defineComponent } from 'vue';
export default defineComponent({
  data: function data() {
    return {
      params: null
    };
  },
  methods: {
    editItem: function editItem() {
      this.params.edit(this.params.data);
    },
    deleteItem: function deleteItem() {
      this.params["delete"](this.params.data);
    }
  }
});