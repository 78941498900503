import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    aktiv: {
      get: function get() {
        var _a, _b;

        return !((_b = (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.deaktiviert);
      },
      set: function set(value) {
        if (this.params.data) {
          editSettingsStore.commit.setRegelSettings({
            kategorie: this.params.data.kategorie,
            id: this.params.data.id,
            value: {
              deaktiviert: !value
            }
          });
        }
      }
    }
  }
});