import { ISettingsPatchError } from '../../../../types/ts';
import { createDirectStore } from 'direct-vuex';
import { editProfileStore } from '@/state/settings/editProfileStore';
import { authStore } from '@/state/authStore';
import { usersStore } from '@/state/usersStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { editFactoringStore } from '@/state/settings/editFactoringStore';
import { isEmpty, omit } from 'lodash';
import * as jsondiffpatch from 'jsondiffpatch';
import Vue from 'vue';

export interface EditSettingsRootState {
  isLoading: boolean;
  initError: string | null;
  errors: { [tabName: string]: { [key: string]: string } };
  settingsPatchError: ISettingsPatchError | null;
}

const settingsRootState: EditSettingsRootState = {
  isLoading: false,
  initError: null,
  errors: {},
  settingsPatchError: null,
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => settingsRootState,
  getters: {
    isModified(): boolean {
      return (
        editProfileStore.getters.isModified ||
        editSettingsStore.getters.isModified ||
        editFactoringStore.getters.isModified
      );
    },
    isValid(state): boolean {
      return isEmpty(state.errors);
    },
    getCurrentSettingsDiff(): jsondiffpatch.Delta {
      return {
        settings: editSettingsStore.getters.getCurrentSettingsDiff,
        profile: editProfileStore.getters.getCurrentProfileDiff,
        factoring: editFactoringStore.getters.getCurrentFactoringDiff,
      };
    },
    getCurrentEditingChanges() {
      return {
        ...editSettingsStore.getters.getCurrentEditingChanges,
        ...editProfileStore.getters.getCurrentEditingChanges,
        ...editFactoringStore.getters.getCurrentEditingChanges,
      };
    },
  },
  mutations: {
    setIsLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    resetErrors(state) {
      state.errors = {};
    },
    addError(state, data: { tabName: string; key: string; error: string }) {
      if (!state.errors[data.tabName]) {
        Vue.set(state.errors, data.tabName, {});
      }
      Vue.set(state.errors[data.tabName], data.key, data.error);
    },
    deleteError(state, data: { tabName: string; key: string }) {
      state.errors[data.tabName] = omit(state.errors[data.tabName], data.key);
      if (isEmpty(state.errors[data.tabName])) {
        Vue.delete(state.errors, data.tabName);
      }
    },
    setPatchValidationError(state, settingsPatchError: ISettingsPatchError) {
      state.settingsPatchError = settingsPatchError;
    },
    resetPatchValidationError(state) {
      state.settingsPatchError = null;
    },
    setInitError(state, error: string) {
      state.initError = error;
    },
  },
  actions: {
    async init(context) {
      const { commit } = rootActionContext(context);
      commit.setIsLoading(true);

      const promises = [
        editProfileStore.dispatch.init(),
        editSettingsStore.dispatch.init(),
        usersStore.dispatch.getData(),
        authStore.dispatch.getViewsAvailable(),
      ];

      if (authStore.getters.hasR4c) {
        promises.push(editFactoringStore.dispatch.init());
      }

      try {
        await Promise.all(promises);
      } catch (err: any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        commit.setInitError(err.message);
      }
      commit.setIsLoading(false);
    },
    async refresh(context, force?: boolean) {
      const forceRefresh = force || false;
      await editSettingsStore.dispatch.init({ refreshOnlyIfUnchanged: !forceRefresh });
    },
    async save(context, opts?: { override: boolean }) {
      try {
        await editProfileStore.dispatch.save();
        await editFactoringStore.dispatch.save();
        await editSettingsStore.dispatch.save(opts);
        await authStore.dispatch.notifySuccess('Die Einstellungen wurden erfolgreich gespeichert.');
      } catch (e) {
        await authStore.dispatch.notifyError('Fehler beim Speichern der Einstellungen');
        console.log('Error while saving settings', e);
      }
    },
    async reset(context) {
      const { commit } = rootActionContext(context);
      await editProfileStore.dispatch.init();
      await editFactoringStore.dispatch.init();
      await editSettingsStore.dispatch.init();
      commit.resetErrors();
    },
  },
});

export const editSettingsRootStore = store;
