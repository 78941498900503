import { sendCommandToUi } from '@rose/common-ui';

export function notifyInfo(message: string) {
  sendCommandToUi({ command: 'notify', level: 0, message });
}
export function notifySuccess(message: string) {
  sendCommandToUi({ command: 'notify', level: 1, message });
}
export function notifyWarning(message: string) {
  sendCommandToUi({ command: 'notify', level: 2, message });
}
export function notifyError(message: string) {
  sendCommandToUi({ command: 'notify', level: 3, message });
}
