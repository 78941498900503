import { IProfile } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { authStore } from '@/state/authStore';
import { isEqual, cloneDeep, isEmpty } from 'lodash';
import { metricsApi } from '@/services/data';
import * as jsondiffpatch from 'jsondiffpatch';
import * as jsonpatch from 'fast-json-patch';

export interface EditProfileState {
  editProfile: IProfile;
}

const editProfileState: EditProfileState = {
  editProfile: {} as IProfile,
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => editProfileState,
  getters: {
    isModified(state): boolean {
      if (!isEqual(authStore.state.profile, state.editProfile)) {
        console.log('profile modified', state.editProfile);
        return true;
      }
      return false;
    },
    getCurrentProfileDiff(state): jsondiffpatch.Delta | undefined {
      return jsondiffpatch
        .create({
          objectHash: function (obj: any, index: number) {
            // try to find an id property, otherwise just use the index in the array
            return obj.id || obj.name || '$$index:' + index;
          },
        })
        .diff(authStore.state.profile, state.editProfile);
    },
    getCurrentEditingChanges(state) {
      // diff with checks
      if (!authStore.state.profile) {
        return [];
      }
      return jsonpatch.compare(authStore.state.profile, state.editProfile, true);
    },
  },
  mutations: {
    setDraftProfile(state, profile: IProfile) {
      state.editProfile = profile;
    },
    setPraxisName(state, praxisName: string) {
      state.editProfile.praxisName = praxisName;
    },
    setPraxisKurzName(state, praxisKurzName: string) {
      state.editProfile.praxisKurzName = praxisKurzName;
    },
    setPraxisTelefon(state, praxisTelefon: string) {
      state.editProfile.praxisTelefon = praxisTelefon;
    },
    setPraxisEmail(state, praxisEmail: string) {
      state.editProfile.praxisEmail = praxisEmail;
    },
    setPraxisNewsEmail(state, praxisNewsEmail: string) {
      state.editProfile.praxisNewsEmail = praxisNewsEmail;
    },
  },
  actions: {
    async init(context) {
      const { state, commit } = rootActionContext(context);
      await authStore.dispatch.getProfile();
      const profile = cloneDeep(authStore.state.profile) || ({} as IProfile);

      if (isEmpty(state.editProfile)) {
        console.log('initializing editProfileState', profile);
      } else {
        console.log('reset editProfileState', profile);
      }

      commit.setDraftProfile(profile);
    },
    async save(context) {
      const { state, getters, dispatch } = rootActionContext(context);

      try {
        if (getters.isModified) {
          console.log('EditProfileStore::updateClientProfile', state.editProfile);
          await metricsApi.profile.updateClientProfile(state.editProfile);
          await authStore.dispatch.getProfile();
          await dispatch.init();
        }
      } catch (e) {
        console.error('error saving profile', e);
        throw e;
      }
    },
  },
});

export const editProfileStore = store;
