import { sumBy, map, size, chain } from 'lodash';
import { ILeistungenSummaryRow, IPatientenUmsaetze } from '../../../types';

export function commonCreateExtendedSummaryRowPatienten(
  patienten: readonly IPatientenUmsaetze[],
  summeryRowsConfig: { title: string | number; yearKey: string; filter: any }[],
): ILeistungenSummaryRow[] {
  let history = summeryRowsConfig.map(({ yearKey, title, filter }) => {
    let leistungenYear: readonly IPatientenUmsaetze[] = map(patienten, (l: any) => l.jahresVerteilung[yearKey]);
    return {
      isSummary: true,
      doFilter: filter,
      isExtended: true,
      honoraroffen: sumUpKey(leistungenYear, 'honoraroffen'),
      materialoffenSum: sumUpKey(leistungenYear, 'materialoffenSum'),
      eigenlaboroffenSum: sumUpKey(leistungenYear, 'eigenlaboroffenSum'),
      fremdlaboroffen: sumUpKey(leistungenYear, 'fremdlaboroffen'),
      honorarerstattungoffenSum: sumUpKey(leistungenYear, 'honorarerstattungoffenSum'),
      gesamt: sumUpKey(leistungenYear, 'gesamt'),
      count: chain(leistungenYear)
        .filter(l => l.gesamt > 0)
        .size()
        .value(),
      title: title,
    };
  });

  return [commonCreateSummaryRowPatienten(patienten), ...history];
}

function sumUpKey(list: readonly any[], key: string) {
  return sumBy(list, l => (l && l[key]) || 0);
}

export function commonCreateSummaryRowPatienten(patienten: readonly IPatientenUmsaetze[]): any {
  const leistungszurechnung = patienten.map(v => v.leistungszurechnung);
  const lgesamt = sumUpKey(patienten, 'lgesamt');

  return {
    isSummary: true,
    isExtended: false,
    count: size(patienten),
    title: 'Summe',
    gesamt: sumUpKey(patienten, 'gesamt'),
    honorar: sumUpKey(patienten, 'honorar'),
    honorargoz: sumUpKey(patienten, 'honorargoz'),
    honorarbema: sumUpKey(patienten, 'honorarbema'),
    honorarmwst: sumUpKey(patienten, 'honorarmwst'),
    material: sumUpKey(patienten, 'material'),
    eigenlabor: sumUpKey(patienten, 'eigenlabor'),
    eigenlaborleistungen: sumUpKey(patienten, 'eigenlaborleistungen'),
    eigenlaborbeb: sumUpKey(patienten, 'eigenlaborbeb'),
    eigenlaborbel: sumUpKey(patienten, 'eigenlaborbel'),
    fremdlabor: sumUpKey(patienten, 'fremdlabor'),
    honorarerstattung: sumUpKey(patienten, 'honorarerstattung'),
    huel: sumUpKey(patienten, 'huel'),
    renichtbezahlt: sumUpKey(patienten, 'renichtbezahlt'),
    rebezahlt: sumUpKey(patienten, 'rebezahlt'),
    reerlassen: sumUpKey(patienten, 'reerlassen'),
    reerstattung: sumUpKey(patienten, 'reerlassen'),
    gesamtnetto: sumUpKey(patienten, 'gesamtnetto'),
    gesamtmwst: sumUpKey(patienten, 'gesamtmwst'),
    nagesamt: sumUpKey(patienten, 'nagesamt'),
    nakasse: sumUpKey(patienten, 'nakasse'),
    naprivat: sumUpKey(patienten, 'naprivat'),
    agesamt: sumUpKey(patienten, 'agesamt'),
    akasse: sumUpKey(patienten, 'akasse'),
    aprivat: sumUpKey(patienten, 'aprivat'),
    nagesamtanteil: (sumUpKey(patienten, 'nagesamt') / lgesamt) * 100,
    nakasseanteil: (sumUpKey(patienten, 'nakasse') / lgesamt) * 100,
    naprivatanteil: (sumUpKey(patienten, 'naprivat') / lgesamt) * 100,
    agesamtanteil: (sumUpKey(patienten, 'agesamt') / lgesamt) * 100,
    akasseanteil: (sumUpKey(patienten, 'akasse') / lgesamt) * 100,
    aprivatanteil: (sumUpKey(patienten, 'aprivat') / lgesamt) * 100,
    honorarnetto: sumUpKey(patienten, 'honorarnetto'),
    materialnetto: sumUpKey(patienten, 'materialnetto'),
    materialmwst: sumUpKey(patienten, 'materialmwst'),
    eigenlabornetto: sumUpKey(patienten, 'eigenlabornetto'),
    eigenlabormwst: sumUpKey(patienten, 'eigenlabormwst'),
    eigenlabormaterialnetto: sumUpKey(patienten, 'eigenlabormaterialnetto'),
    eigenlabormaterialmwst: sumUpKey(patienten, 'eigenlabormaterialmwst'),
    eigenlabormaterial: sumUpKey(patienten, 'eigenlabormaterial'),
    fremdlabornetto: sumUpKey(patienten, 'fremdlabornetto'),
    fremdlabormwst: sumUpKey(patienten, 'fremdlabormwst'),
    leistungszurechnung: {
      BEMAGesamt: sumUpKey(leistungszurechnung, 'BEMAGesamt'),
      BEMA1: sumUpKey(leistungszurechnung, 'BEMA1'),
      BEMA2: sumUpKey(leistungszurechnung, 'BEMA2'),
      BEMA3: sumUpKey(leistungszurechnung, 'BEMA3'),
      BEMA4: sumUpKey(leistungszurechnung, 'BEMA4'),
      BEMA5: sumUpKey(leistungszurechnung, 'BEMA5'),
      BEMABAE: sumUpKey(leistungszurechnung, 'BEMABAE'),
      BEMABU: sumUpKey(leistungszurechnung, 'BEMABU'),
      OhneZurechnung: sumUpKey(leistungszurechnung, 'OhneZurechnung'),
      GOZGesamt: sumUpKey(leistungszurechnung, 'GOZGesamt'),
      GOZA: sumUpKey(leistungszurechnung, 'GOZA'),
      GOZB: sumUpKey(leistungszurechnung, 'GOZB'),
      GOZC: sumUpKey(leistungszurechnung, 'GOZC'),
      GOZD: sumUpKey(leistungszurechnung, 'GOZD'),
      GOZE: sumUpKey(leistungszurechnung, 'GOZE'),
      GOZF: sumUpKey(leistungszurechnung, 'GOZF'),
      GOZG: sumUpKey(leistungszurechnung, 'GOZG'),
      GOZH: sumUpKey(leistungszurechnung, 'GOZH'),
      GOZJ: sumUpKey(leistungszurechnung, 'GOZJ'),
      GOZK: sumUpKey(leistungszurechnung, 'GOZK'),
      GOZL: sumUpKey(leistungszurechnung, 'GOZL'),
      GOZV: sumUpKey(leistungszurechnung, 'GOZV'),
      GOZGU: sumUpKey(leistungszurechnung, 'GOZGU'),
      GOZGAE: sumUpKey(leistungszurechnung, 'GOZGAE'),
    },
  };
}
