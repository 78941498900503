import { IBemaGozStammdaten, ITerminart, IBehandlerTerminGruppe, IAllParams } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { metricsApi } from '@/services/data';
import { sortBy, isEmpty } from 'lodash';
import { fixupVersionString } from '@/helpers/settings';

export interface StaticDataMetricsState {
  params: IAllParams;
  bemagoz: IBemaGozStammdaten[];
  terminarten: ITerminart[];
  termingruppen: IBehandlerTerminGruppe[];
}

const staticDataMetricsState: StaticDataMetricsState = {
  params: {} as IAllParams,
  bemagoz: {} as IBemaGozStammdaten[],
  terminarten: {} as ITerminart[],
  termingruppen: {} as IBehandlerTerminGruppe[],
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => staticDataMetricsState,
  getters: {
    termingruppenNamen(state) {
      return sortBy(state.termingruppen, v => v.name).map(v => v.name);
    },
    hasHelloCharlyInterface(state) {
      return state.params?.schnittstellen?.includes('ExportSolutioSpeech');
    },
    hasR4cInterface(state) {
      return state.params?.schnittstellen?.includes('ExportRose4Charly');
    },
    hasMultiRecallInterface(state) {
      return state.params.multirecall;
    },
  },
  mutations: {
    setParams(state, data: IAllParams) {
      state.params = data;
    },
    setBemaGoz(state, data: IBemaGozStammdaten[]) {
      state.bemagoz = data;
    },
    setTerminarten(state, data: ITerminart[]) {
      state.terminarten = data;
    },
    setTermingruppen(state, data: IBehandlerTerminGruppe[]) {
      state.termingruppen = data;
    },
  },
  actions: {
    async init(context) {
      const { state, commit } = rootActionContext(context);
      const [params, bemaGoz, terminArten, terminGruppen] = await Promise.all([
        metricsApi.staticData.getParams(),
        metricsApi.staticData.getBemaGoz(),
        metricsApi.staticData.getTerminarten(),
        metricsApi.staticData.getTermingruppen(),
      ]);

      if (
        state.bemagoz.length === 0 ||
        state.terminarten.length === 0 ||
        state.termingruppen.length === 0 ||
        isEmpty(state.params)
      ) {
        console.log('initializing staticDataMetricsState', params, bemaGoz, terminArten, terminGruppen);
      } else {
        console.log('reset editProfileState', params, bemaGoz, terminArten, terminGruppen);
      }

      commit.setParams({ ...params, pvsversion: fixupVersionString(params.pvsversion as string) });
      commit.setBemaGoz(bemaGoz);
      commit.setTerminarten(terminArten);
      commit.setTermingruppen(terminGruppen);
    },
  },
});

export const staticDataMetricsStore = store;
