import { rechnungenStore } from '@/state/rechnungenStore';
import { createSummaryRow } from '../../../common-ui/src/directImports/rechnungen.service';
import { IRechnungExtendedWithVeraltetLeistungenFiltered } from '../../../types';

export function createExtendedSummaryRow(showExtendedSummaryRow: boolean): any {
  const rechnungen: IRechnungExtendedWithVeraltetLeistungenFiltered[] = [...rechnungenStore.getters.rechnungenFiltered];

  function createSummaryRowFromSpezialFilter(displayName: string, filterKey: string, groupFilterSubKey: string) {
    let rowFilter: any = rechnungenStore.getters.filterGroups[filterKey]?.filters.find(
      f => f.key === groupFilterSubKey,
    )?.rowFilter;

    if (!rowFilter) {
      throw new Error(`spezial filter ${displayName}: ${[filterKey, groupFilterSubKey].join('.')} not found`);
    }

    return createSummaryRow(
      rechnungen.filter(rowFilter),
      showExtendedSummaryRow,
      displayName,
      null,
      groupFilterSubKey ? [`${filterKey}.${groupFilterSubKey}`] : [filterKey],
      rowFilter,
    );
  }

  const zahlungszielUeberschritten = createSummaryRowFromSpezialFilter(
    'Zahlungsziel überschritten',
    'zahlungsziel',
    'zahlungszielUeberschritten',
  );
  const zahlungszielZukunft = createSummaryRowFromSpezialFilter(
    'Zahlungsziel in der Zukunft',
    'zahlungsziel',
    'zahlungszielZukunft',
  );
  const mahnung1 = createSummaryRowFromSpezialFilter('   1. Mahnung', 'mahnung', 'mahnung1');
  const mahnung2 = createSummaryRowFromSpezialFilter('   2. Mahnung', 'mahnung', 'mahnung2');
  const mahnung3 = createSummaryRowFromSpezialFilter('   3. Mahnung', 'mahnung', 'mahnung3');
  const anwalt = createSummaryRowFromSpezialFilter('   Anwalt', 'mahnung', 'mahnung4');
  const realisierbar = createSummaryRowFromSpezialFilter('Realisierbar', 'realisierbar', 'realisierbar');
  const verjahrtJahresende = createSummaryRowFromSpezialFilter(
    '   Verjährt Jahresende',
    'realisierbar',
    'verjahrtJahresende',
  );
  const verjahrt = createSummaryRowFromSpezialFilter('Verjährt', 'realisierbar', 'verjahrt');
  const rechenzentrum = createSummaryRowFromSpezialFilter('Rechenzentrum', 'rz', 'rz');

  return [
    createSummaryRow(rechnungen, showExtendedSummaryRow),
    zahlungszielUeberschritten,
    zahlungszielZukunft,
    mahnung1,
    mahnung2,
    mahnung3,
    anwalt,
    realisierbar,
    verjahrtJahresende,
    verjahrt,
    rechenzentrum,
  ];
}
